import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactMarkdown from 'react-markdown';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    padding: 1rem;
    background-color: #f0f4f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
`;

const HeaderSection = styled.div`
    width: 80%;
    margin-bottom: 2rem;
    background: #1e293b;
    border-radius: 8px;
    overflow: hidden;
    color: #f8fafc;
`;

const TopHeader = styled.div`
    padding: 1rem 1.5rem;
    background: #111827;
    color: white;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 0.3rem;
    font-size: 0.9rem;
    color: #e2e8f0;
`;

const Title = styled.input`
    width: 100%;
    font-size: 1.5rem;
    font-weight: 600;
    color: #f8fafc;
    background: transparent;
    border: 1px solid #4b5563;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    caret-color: #3b82f6;

    &::placeholder {
        color: rgba(248, 250, 252, 0.5);
    }

    &:focus {
        outline: none;
        border-color: #3b82f6;
        background: #374151;
    }
`;

const TagsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 0.5rem;
`;

const TagInput = styled.input`
    background: #374151;
    border: 1px solid #4b5563;
    border-radius: 4px;
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
    color: #f8fafc;
    caret-color: #3b82f6;

    &::placeholder {
        color: rgba(248, 250, 252, 0.5);
    }

    &:focus {
        outline: none;
        border-color: #3b82f6;
        background: #4b5563;
    }
`;

const BottomHeader = styled.div`
    padding: 1rem 1.5rem;
    border-top: 1px solid #4b5563;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    background: #111827;
`;

const NotesArea = styled.textarea`
    width: 100%;
    border: 1px solid #4b5563;
    background: #1f2937;
    font-family: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, monospace;
    font-size: 0.85rem;
    color: #d1d5db;
    line-height: 1.4;
    resize: vertical;
    padding: 0.5rem;
    caret-color: #3b82f6;
    border-radius: 4px;

    &:focus {
        outline: none;
        border-color: #3b82f6;
        background: #374151;
    }

    &::placeholder {
        color: #9ca3af;
    }
`;

const MetaInput = styled.input`
    width: 100%;
    border: 1px solid #4b5563;
    background: #1f2937;
    font-size: 0.85rem;
    color: #d1d5db;
    padding: 0.5rem;
    caret-color: #3b82f6;
    border-radius: 4px;

    &:focus {
        outline: none;
        border-color: #3b82f6;
        background: #374151;
    }

    &::placeholder {
        color: #9ca3af;
    }
`;

const CardGroup = styled.div`
    width: 80%;
    background: white;
    padding: 0.8rem;
    border-radius: 6px;
    margin-bottom: 0.8rem;
    border: 1px solid #e2e8f0;
    cursor: move;
    opacity: ${props => props.isDragging ? 0.5 : 1};
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.85rem;
`;

const IdBadge = styled.div`
    font-family: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, monospace;
    font-size: 0.65rem;
    color: #94a3b8;
`;

const LabelQ = styled.span`
    font-weight: 700;
    color: #1e293b;
    margin-right: 0.3rem;
`;

const LabelR = styled.span`
    font-weight: 700;
    color: #1e293b;
    margin-right: 0.3rem;
`;

const StyledTextArea = styled.textarea`
    width: 100%;
    border: 1px solid #d1d5db;
    background: #f9fafb;
    font-size: 0.85rem;
    line-height: 1.4;
    resize: none;
    overflow: hidden;
    min-height: 2rem;
    color: #1f2937;
    caret-color: #1e40af;
    border-radius: 4px;
    padding: 0.5rem;

    &::placeholder {
        color: #9ca3af;
    }

    &:focus {
        outline: none;
        border-color: #3b82f6;
        background: #e5e7eb;
    }
`;

const PreviewContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
`;

const Preview = styled.div`
    flex: 1;
    padding: 0.5rem;
    background: #f1f5f9;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    max-height: 200px;
    overflow-y: auto;
    font-size: 0.85rem;
`;

const ShowPreviewButton = styled.button`
    background: none;
    border: none;
    color: #3b82f6;
    cursor: pointer;
    padding: 0.2rem;
    display: flex;
    align-items: center;
    font-size: 0.75rem;

    &:hover {
        color: #2563eb;
    }
`;

const ShortcutHint = styled.div`
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    padding: 0.4rem 0.8rem;
    background: #1e293b;
    color: white;
    border-radius: 4px;
    font-size: 0.75rem;
    opacity: 0.9;
`;

const AutoGrowTextArea = React.forwardRef(({ value, onChange, ...props }, ref) => {
    const textAreaRef = useRef(null);

    useEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [value]);

    const handleChange = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
        onChange?.(e);
    };

    return (
        <StyledTextArea
            ref={(node) => {
                textAreaRef.current = node;
                if (typeof ref === 'function') ref(node);
                else if (ref) ref.current = node;
            }}
            value={value}
            onChange={handleChange}
            {...props}
        />
    );
});

const Card = ({ id, data, index, moveCard, updateCard }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: { id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'card',
        hover: (item, monitor) => {
            if (item.index !== index) {
                moveCard(item.index, index);
                item.index = index;
            }
        },
    });

    const [showPreviewQ, setShowPreviewQ] = useState(false);
    const [showPreviewR, setShowPreviewR] = useState(false);

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'Enter') {
            e.preventDefault();
            window.dispatchEvent(new CustomEvent('createNewCard', { 
                detail: { afterIndex: index } 
            }));
        }
    };

    return (
        <CardGroup 
            ref={node => drag(drop(node))} 
            isDragging={isDragging}
            onKeyDown={handleKeyDown}
        >
            <IdBadge>{data.id}</IdBadge>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <LabelQ>Q:</LabelQ>
                <AutoGrowTextArea
                    value={data.question}
                    onChange={(e) => updateCard(index, { ...data, question: e.target.value })}
                    placeholder="Pergunta..."
                />
                <ShowPreviewButton onClick={() => setShowPreviewQ(!showPreviewQ)} title="Mostrar Pré-visualização">
                    {showPreviewQ ? <FaEyeSlash /> : <FaEye />}
                </ShowPreviewButton>
            </div>
            {showPreviewQ && (
                <Preview>
                    <ReactMarkdown>{data.question}</ReactMarkdown>
                </Preview>
            )}
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <LabelR>R:</LabelR>
                <AutoGrowTextArea
                    value={data.answer}
                    onChange={(e) => updateCard(index, { ...data, answer: e.target.value })}
                    placeholder="Resposta..."
                />
                <ShowPreviewButton onClick={() => setShowPreviewR(!showPreviewR)} title="Mostrar Pré-visualização">
                    {showPreviewR ? <FaEyeSlash /> : <FaEye />}
                </ShowPreviewButton>
            </div>
            {showPreviewR && (
                <Preview>
                    <ReactMarkdown>{data.answer}</ReactMarkdown>
                </Preview>
            )}
        </CardGroup>
    );
};

export default function FlashcardEditor() {
    const [cards, setCards] = useState([
        {
            id: 'flashcards_doençaArterialObstrutivaPeriférica2024_01',
            question: '**Cirurgia Vascular.** {!Doença arterial obstrutiva periférica (DAOP)}} é a doença aterosclerótica que envolve as artérias que fornecem sangue para as extremidades.',
            answer: '*Em outras fontes o termo DAOP é considerado um termo geral que abrange uma série de síndromes arteriais não coronárias causadas por alterações na estrutura e função da aorta e artérias periféricas.* \n*Entretanto, o UpToDate (e esse deck) considera que o termo denota doença arterial que afeta a vasculatura periférica (não coronária) devido à aterosclerose.*'
        },
        {
            id: 'flashcards_doençaArterialObstrutivaPeriférica2024_02',
            question: '!@# Muito comumente acomete as extremidades {!inferiores || inferiores / superiores}}.',
            answer: '*Doença arterial de membros superiores existe, mas é bem incomum.*'
        },
        {
            id: 'flashcards_doençaArterialObstrutivaPeriférica2024_03',
            question: '!@# A placa tende a ocorrer em quais segmentos arteriais?',
            answer: '**Proximais ou médios.**\n*Os sintomas relacionados ao estreitamento aterosclerótico dependem da localização e da gravidade da doença, que segue padrões anatômicos, também influenciando sua história natural e progressão.*\n*De nota, pacientes com diabetes ou doença renal terminal geralmente apresentam doença mais distal.*'
        },
        {
            id: 'flashcards_doençaArterialObstrutivaPeriférica2024_04',
            question: '!@# Qual o sentido da vida?',
            answer: '**Morrer.**'
        }
    ]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards(prevCards => {
            const newCards = [...prevCards];
            const dragCard = newCards[dragIndex];
            newCards.splice(dragIndex, 1);
            newCards.splice(hoverIndex, 0, dragCard);
            return newCards;
        });
    }, []);

    const updateCard = useCallback((index, updatedCard) => {
        setCards(prevCards => {
            const newCards = [...prevCards];
            newCards[index] = updatedCard;
            return newCards;
        });
    }, []);

    const createNewCard = useCallback((afterIndex) => {
        setCards(prevCards => {
            const newCards = [...prevCards];
            const newId = `flashcards_doençaArterialObstrutivaPeriférica2024_${prevCards.length + 1}`;
            newCards.splice(afterIndex + 1, 0, {
                id: newId,
                question: '',
                answer: ''
            });
            return newCards;
        });
    }, []);

    useEffect(() => {
        const handleCreateNewCard = (e) => {
            if (e.detail && typeof e.detail.afterIndex === 'number') {
                createNewCard(e.detail.afterIndex);
            }
        };

        window.addEventListener('createNewCard', handleCreateNewCard);
        return () => window.removeEventListener('createNewCard', handleCreateNewCard);
    }, [createNewCard]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Container>
                <HeaderSection>
                    <TopHeader>
                        <Label htmlFor="deck-title">Título do Deck</Label>
                        <Title 
                            id="deck-title"
                            defaultValue="Doença Arterial Obstrutiva Periférica"
                            placeholder="Título do deck..."
                        />
                        <Label>Tags</Label>
                        <TagsGrid>
                            <TagInput defaultValue="Clínica Cirúrgica" placeholder="Tag 1" />
                            <TagInput defaultValue="Cirurgia Vascular" placeholder="Tag 2" />
                            <TagInput defaultValue="Doença Arterial Obstrutiva Periférica" placeholder="Tag 3" />
                            <TagInput placeholder="Tag 4" />
                        </TagsGrid>
                    </TopHeader>
                    
                    <BottomHeader>
                        <Label htmlFor="notes">Notas e Lembretes</Label>
                        <NotesArea 
                            id="notes"
                            defaultValue="// classificação de fontaine e rutherford
// anedota do René Leriche
// falar sobre o cilostazol"
                            placeholder="Notas e lembretes..."
                        />
                        <Label htmlFor="references">Referências</Label>
                        <MetaInput 
                            id="references"
                            defaultValue="Overview of lower extremity peripheral artery disease e Lower extremity peripheral artery disease: Clinical features and diagnosis, em UpToDate."
                            placeholder="Referências..."
                        />
                        <Label htmlFor="prefix">Prefixo</Label>
                        <MetaInput 
                            id="prefix"
                            defaultValue="Doença Arterial Obstrutiva Periférica."
                            placeholder="Prefixo..."
                        />
                    </BottomHeader>
                </HeaderSection>

                {cards.map((card, index) => (
                    <Card
                        key={card.id}
                        id={card.id}
                        data={card}
                        index={index}
                        moveCard={moveCard}
                        updateCard={updateCard}
                    />
                ))}

                <ShortcutHint>
                    CTRL + ENTER = Novo Card | Drag & Drop para reordenar
                </ShortcutHint>
            </Container>
        </DndProvider>
    );
}
