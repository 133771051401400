import React, { useEffect, useState, } from 'react'
import styled from 'styled-components'
import StatisticsCard from './StatisticsCard'
import StatisticsPie from './StatisticsPie'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLegend, VictoryScatter, VictoryStack, VictoryTheme, VictoryTooltip, VictoryVoronoi, VictoryVoronoiContainer } from 'victory'
import { XAxis } from 'recharts'
import OslerHeatmap from './OslerHeatmap'
import LastDaysCards from './LastDaysCards'
import LastDaysPies from './LastDaysPies'
import LastDaysChart from './LastDaysChart'
import NextDaysReviews from './NextDaysReviews'
import StatisticsExplanationCard from './StatisticsExplanationCard'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import OslerButton from '../components/OslerButton'
import { getDailyUse, getDailyUseTestTypes, sumDailyData } from './DailyUseMethod'
import { useSelector } from 'react-redux'
import IsLoading from '../main/IsLoading'
import ExhibitionTypeSelector from './ExhibitionTypeSelector'




const Container = styled.div`
    ${ColumnCSS}
    padding: 0;
    margin: 0;
    width: 100%;
`

export const TooltipContainer = styled.div`
    display: flex;
    width: 35px
    height: 35px;
`




export default function LastDaysStatistics() {

    const user = useSelector(state => state.user.data)
    const [testType, setTestType] = useState('Flashcards')
    const [summedDailyData, setSummedDailyData] = useState(false)



    const [dailyUse, setDailyUse] = useState(false)


    useEffect(() => {
        async function fetchData() {
            const lastXDays = 30
            const data = await getDailyUseTestTypes(user.id, lastXDays)
            setDailyUse(data)
        }

        fetchData()
    }, [])


    useEffect(() => {
        if (dailyUse) {
            const data = {
                'Flashcards' : sumDailyData(dailyUse['Flashcards']),
                'Residencia' : sumDailyData(dailyUse['Residencia'])
            }
    
            // Fazemos as somas de algumas variáveis para a exibição no 
            // LastDaysCards.
            const F = data['Flashcards']
            const R = data['Residencia']
    
            data['Ambos'] = {
                'daysStudied' : Math.max(F['daysStudied'], R['daysStudied']),
                'timeSpent' : F['timeSpent'] + R['timeSpent'],
                'nTestsAnswered' : F['nTestsAnswered'] + R['nTestsAnswered'],
            }
    
            setSummedDailyData(data)
        }
    }, [dailyUse])




    if (!summedDailyData) {
        return <></>
    }

    if (!dailyUse) {
        return (
            <IsLoading />
        )
    }

    if (dailyUse.length == 0) {
        return(
            <StatisticsExplanationCard
                emoji = '😫'
                highlight = 'Mas você não fez nenhum teste'
                text = 'Sem testes, sem dados, sem gráficos.' />
        ) 
    }

    const getSelector = () => <ExhibitionTypeSelector setType={setTestType} exhibitionType={testType} />

    return (    
        <Container>

            { summedDailyData[testType].nTestsAnswered > 0 &&
                <>
                    <StatisticsExplanationCard
                        emoji = '🗓️'
                        highlight = 'Últimos 30 dias de estudo'
                        text = 'Abaixo, você avalia como foi o último mês de estudo. E você terá o insight óbvio: um pouco a mais, todos os dias, é a chave.' />

                    { getSelector() }        


                    <LastDaysCards 
                        testType = {testType}
                        dailyData = {summedDailyData[testType]}
                        nDays = {30} />


                    <LastDaysPies
                        testType = {testType}
                        dailyData = {summedDailyData} />

                    
                    <LastDaysChart
                        style = {{marginTop: '2em'}}
                        exhibitionType = {testType}
                        dailyData = {dailyUse} />
                </>
            }

            { !summedDailyData[testType].nTestsAnswered &&   
                            <StatisticsExplanationCard
                            emoji = '🗓️'
                            highlight = 'Últimos 30 dias de estudo'
                            text = 'Abaixo, teríamos dados sobre como foi o último mês de estudo, mas... Você não fez nada.' />

            }



            
            
            <NextDaysReviews
                exhibitionType = {testType}
                dailyData = {summedDailyData}
                selector={getSelector()} /> 

 
            <OslerHeatmap
                exhibitionType = {testType}
                selector={getSelector()} />

        </Container>
    )
}