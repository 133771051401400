import styled from 'styled-components';
import { latestVersionHardCoded } from '../App';

const VersionContainer = styled.div`
  width: 100%;
padding: -1em;

`

const VersionText = styled.span`
  color: gray;
  font-size: 0.6rem;
`;

export default function Version() {
  return (
    <VersionContainer>
      <VersionText>Versão {latestVersionHardCoded}</VersionText>
    </VersionContainer>
  );
}