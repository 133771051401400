
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { 
    Book, 
    BookOpen, 
    Award, 
    Target, 
} from 'lucide-react'


import Anna from './Anna'
import { useSelector, useDispatch } from 'react-redux'
import { setIsLoading } from './../redux/loadingSlice'
import sessionBuilder from './../controllers/SessionBuilder'
import userReviewsInfo from './../controllers/UserReviewsInfo'
import { useNavigate } from "react-router-dom";
import './MainScreen.css'
import { guaranteeSubscription } from '../firebase/firebaseUtils';
import TestsDoneGraph from '../statistics/TestsDoneGraph'
import SurpriseSessionCard from './SurpriseSessionCard'
import NoticeCard from './NoticeCard'
import { loadAppData } from '../services/LoadAppData'
import personalNotes from '../controllers/PersonalNotes'
import MistakesJournalController from '../controllers/MistakesJournalController'
import Notebook from '../controllers/NotebookController'
import TitleCard from '../custom/TitleCard'
import PendingReviews from './PendingReviews'
import LastSessions from './LastSessions'
import LastSessionController from '../controllers/LastSessionController'
import Session from './../controllers/Session'
import MainScreenHeader from './MainScreenHeader'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'

import FlameIcon from './../assets/flame.png'
import ServerTester from './ServerTester'
import api from '../firebase/axios-setup'
import Version from './Version'

// Container principal com background sutilmente texturizado
const Container = styled.div`
    ${ColumnCSS}

    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 2em;
    
    background-size: 24px 24px;

    @media (max-width: 900px) {
        padding: 1em;
    }

    @media (max-width: 600px) {
        padding: 0.5em;
    }
`





const MainRow = styled.div`
    ${RowCSS}    
    width: 100%;
    justify-content: space-around;
    margin: 2em 0 1em 0;

    @media (max-width: 900px) {
        ${ColumnCSS}
        gap: 2em;
    }
`


// Continua na próxima mensagem com a implementação do componente principal e demais elementos...

export default function MainScreen() {

    const user = useSelector(state => state.user.data)
    let navigate = useNavigate();
    const dispatch = useDispatch()


    useEffect(() => {
        console.log("MainScreen: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])
    
    const [downloadedReviewInfo, setDownloadedReviewInfo] = useState(false)
    const [downloadedTodayInfo, setDownloadTodayInfo] = useState(false)


    useEffect(() => {
        async function loadMain() {         
            dispatch( setIsLoading(true) )


            // try {
            //     console.log('calling app data for ' + user.id)
            //     const { data } = await api.get('/app-data', { 
            //         params: { userId: user.id }
            //     });
            //     console.log(data)

            //   } catch (error) {
            //     console.error('Error fetching app data:', error);
            //   }


            try {
                console.log('Vamos carregar')
                await loadAppData(user)





                // await OslerData.start(user)

                // não tinha await antes?! ?! ?! 
                // await measureTime(() => userReviewsInfo.start(), 'UserReviewsInfo(): started in')


                const tests_per_tag = userReviewsInfo.getTestsPerTag()
                sessionBuilder.prepare(user, userReviewsInfo, tests_per_tag) 
                Session.prepare(user)

                personalNotes.construct(user)
                MistakesJournalController.construct(user)
                Notebook.construct(user)
                LastSessionController.prepare(user, userReviewsInfo)

                setDownloadedReviewInfo(true)    
                setDownloadTodayInfo(true)    



                dispatch( setIsLoading(false) )

            }
            catch (error) {
                navigate('/error', { state: { msg: 'loadMain(): error', data: error } });
            }



        }

        console.log('MainScreen: starting...')
        userReviewsInfo.construct(user, ['Flashcards', 'Residencia'])
        loadMain()
    }, [])



    // Trocar por spinner no futuro
    if (!downloadedReviewInfo || !downloadedTodayInfo) return
    return (
        <Container>

            <Version />


          <MainScreenHeader /> 



            <MainRow>
                <NoticeCard />
            </MainRow>

            <MainRow>
                <PendingReviews />

                <SurpriseSessionCard />
            </MainRow>

            <MainRow>
                <LastSessions />
            </MainRow>

            <MainRow>
                <TitleCard
                    icon = {FlameIcon}
                    title = 'Última semana'>

                        <TestsDoneGraph />

                    </TitleCard>
            </MainRow>
            
            </Container>
        )
}