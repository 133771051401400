import React from 'react'
import { useNavigate } from "react-router-dom";
import './Buy2.css';
import ShoppingCart from './../assets/shopping-cart.png'
import ShieldIcon from './../assets/shield.png'
import Logo2 from './../assets/signature.png'
import Offer from './Offer';


export default function Buy2() {

    let navigate = useNavigate();

    function action(planID) {
        // Recebe o plano escolhido como parâmetro, mas não
        // utiliza.
        navigate('/login')
    }

	return (
		<div className="section section-light-bg buy2-container">
            
            {/* <SectionTitle
                title="Qualidade tem preço." /> */}

            
            {/* <div className = 'black-friday-title-div'>
                <p className = 'black-friday-txt'>🚀 BLACK... MAY? 🚀</p>
            </div> */}


            <Offer
                buttonText = {'Garantir esse preço'}
                action = {action} />


            <div className = 'lastRemarksContainer'>
                <div className = 'lastRemark'>

                    <div className = 'lastRemark-IconContainer'>
                        <img src = {Logo2} className = 'lastRemarkIcon' />
                    </div>

                    <div className = 'lastRemark-TextContainer'>
                        <p className = 'lastRemarkText'><strong>Acesso a toda a plataforma</strong> (Flashcards, multimídia, banco de questões, Extensivo/Intensivão) e a melhorias futuras.</p>
                    </div>
                </div>

                <div className = 'lastRemark'>

                    <div className = 'lastRemark-IconContainer'>
                        <img src = {ShieldIcon} className = 'lastRemarkIcon' />
                    </div>

                    <div className = 'lastRemark-TextContainer'>
                        <p className = 'lastRemarkText'><strong>Sem risco:</strong> nos primeiros 7 dias, você pode pedir cancelamento e receberá reembolso integral.</p>
                    </div>
                </div>



                <div className = 'lastRemark'>
                    <div className = 'lastRemark-IconContainer'>
                        <img src = {ShoppingCart} className = 'lastRemarkIcon' />
                    </div>

                    <div className = 'lastRemark-TextContainer'>
                        <p className = 'lastRemarkText'>Pagamento via <strong>cartão de crédito</strong> (até 12x, acesso imediato) ou <strong>boleto</strong> (à vista, acesso na compensação).</p>
                    </div>
                </div>
            </div>

        </div>
	)
}