import React, { useState } from 'react'
import styled from 'styled-components'
import GeneralDialog from '../tests/GeneralDialog'
import DialogAction from './ExamsDialogAction'
import ReactSwitch from 'react-switch'

import examIcon from './../assets/exam.png'
import fullExamIcon from './../assets/exam.png'
import reviewIcon from './../assets/tree/dumbbell.png'
import newQuestionsIcon from './../assets/tree/notebook.png'
import consultModeIcon from './../assets/books.png'
import playgroundModeIcon from './../assets/sandbox.png'
import { setIsLoading } from '../redux/loadingSlice'
import Session from '../controllers/Session'
import { toastMsg } from '../utils/Utils'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SessionBuilder from '../controllers/SessionBuilder'
import { PredefinedSessionConfig, TEST_TYPES } from '../controllers/SessionConfig'
import { useSessionStarter } from '../utils/StartSessionMethods'




const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @media (max-width: 768px) {
        gap: 15px;
        padding: 10px;
    }
`

const SimulatedModeSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: ${props => props.theme.darkMode ? '#2c3e50' : '#f0f4f8'};
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 15px;
        flex-direction: column;
        gap: 15px;
    }
`

const SimulatedModeInfo = styled.div`
    flex: 1;

    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
`

const SimulatedModeTitle = styled.h3`
    font-size: 1.2em;
    margin-bottom: 8px;
    color: ${props => props.theme.darkMode ? '#ecf0f1' : '#2c3e50'};

    @media (max-width: 768px) {
        font-size: 1.1em;
        margin-bottom: 6px;
    }
`

const SimulatedModeDescription = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.darkMode ? '#bdc3c7' : '#34495e'};
    max-width: 70%;

    @media (max-width: 768px) {
        font-size: 0.7em !important;
        max-width: 100%;
        margin: 0 auto;
    }
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;

    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0 10px;
    }
`

export default function ResidenciaListsDialog({ selectedList, showDialog, setShowDialog }) {
    const [simulatedMode, setSimulatedMode] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { startNewSession } = useSessionStarter()


    function loadSession(option) {
        dispatch(setIsLoading(true))

        console.log(selectedList)

        let mode = 'test-mode'
        let testIDs = selectedList.testIDs
        switch (option) {
            case 'only_reviews':
                testIDs = selectedList.lastWrongAnswers
                break

            case 'only_news':
                testIDs = selectedList.neverAnswered
                break

            case 'playground':
                mode = 'playground-mode'
                break

            case 'consult':
                mode = 'consult-mode'
                break
        }




        startNewSession({
            testType: TEST_TYPES.RESIDENCIA,
            builder: 'predefined',
            studyMode: mode,
            sessionConfig: PredefinedSessionConfig.create(),
            testIDs: testIDs,
        })


        // Lado ruim: não é algo "default", só será salvo no lastSession se o usuário responde >= 1
        // teste. Deveria ser um callback via startNewSession
        if (Session.sessionSize > 0) {
            Session.addList(selectedList.id)
            Session.mainTheme = selectedList.name
        }
    }




    function prepareSession(mode, customList = false) {
        if (Session.sessionSize > 0) {
            if (customList) {
                Session.addList(customList)
            }
            Session.addTemporaryListener(mode === 'consult-mode' ? moveToConsult : moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/test")
        dispatch(setIsLoading(false))
    }


    function moveToConsult() {
        navigate("/consult")
        dispatch(setIsLoading(false))
    }


    if (!showDialog) return;

    return (
        <GeneralDialog
            open={showDialog}
            icon={examIcon}
            title = { selectedList.name }
            onClose={() => setShowDialog(false)}
        >
            <DialogContent>
                {/* Já temoso, deveria generalizar TODO */}
                <SimulatedModeSection>
                    <SimulatedModeInfo>
                        <SimulatedModeTitle>Modo Simulado</SimulatedModeTitle>
                        <SimulatedModeDescription>
                            Neste modo, a plataforma funcionará como uma prova real: você só verá as respostas após encerrar todas as questões.
                        </SimulatedModeDescription>
                    </SimulatedModeInfo>

                    <ReactSwitch
                        checked={simulatedMode}
                        onChange={() => setSimulatedMode(!simulatedMode)}
                        onColor="#86d3ff"
                        offColor="#767577"
                        offHandleColor="#c4c4c4"
                        onHandleColor="#2693e6"
                        handleDiameter={24}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                    />
                </SimulatedModeSection>

                <ActionsContainer>
                    <DialogAction
                        main={<><img src={fullExamIcon} alt='Full Exam' /><p>Lista</p></>}
                        explanation='Todas as questões, independente de já tê-las visto.'
                        onClick={() => loadSession('all')}
                    />

                    { selectedList.lastWrongAnswers.length > 0 &&
                        // {/* Veja que é levemente diferente do caderno de erros... */}
                        <DialogAction
                            main={<><img src={reviewIcon} alt='Review' /><p>Corrigir</p></>}
                            explanation='Somente as que você errou da última vez que respondeu.'
                            onClick={() => loadSession('only_reviews')}
                        />
                    }

                    { selectedList.neverAnswered.length > 0 &&
                        <DialogAction
                            main={<><img src={newQuestionsIcon} alt='New Questions' /><p>Novas</p></>}
                            explanation='Somente as questões que não viu até agora.'
                            onClick={() => loadSession('only_news')}
                        />                    
                    }

                    <DialogAction
                        main={<><img src={consultModeIcon} alt='Consult Mode' /><p>Consulta</p></>}
                        explanation='Você visualizará as questões, incluindo gabarito e explicação, sem precisar responder.'
                        onClick={() => loadSession('consult')}
                    />
                    <DialogAction
                        main={<><img src={playgroundModeIcon} alt='Playground Mode' /><p>Playground</p></>}
                        explanation='Responda todas as questões, mas sem afetar as estatísticas.'
                        onClick={() => loadSession('playground')}
                    />
                </ActionsContainer>
            </DialogContent>
        </GeneralDialog>
    )
}