import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Checkbox from './Checkbox'
import ExpandCollapse from './ExpandCollapse'
import { ColumnCSS, RowCSS } from '../components/BasicComponents.js'
import Dumbbell from './../assets/tree/dumbbell.png'
import Notebook from './../assets/tree/notebook.png'
import Kettlebell from './../assets/tree/kettlebell.png'
import KettlebellDumbbell from './../assets/tree/kettelbellDumbbell2.png'
import Books from './../assets/books.png'
import SandboxIcon from './../assets/sandbox.png'
import Rocket from './../assets/tree/rocket vertical.png'
import { motion, AnimatePresence } from 'framer-motion'


const Container = styled(motion.div)`
    ${RowCSS}
    justify-content: space-between;

    width: 100%;
    
    // A altura é definida explicitamente, ao invés de ser uma funcão do padding vertical.
    // O paddinh horizontal é importante para ficar bonito ao hover. No mobile, precisa ser
    // menor.

    // box-sizing: content-box;
    padding: 0 1em 0 1em;
    
    ${({ depth })=> depth === 0 ? `
        height: 4.5rem;
    `:`
        height: 2.5rem;
    `}

    // background-color: red !important;


    ${({ depth, userIsSearching }) => {
        if (userIsSearching) {
            return `
                // height: ${depth === 0 ? '2em' : '1em'};
                // padding: ${depth === 0 ? '0.5em 1em' : '1.5em 1em'};
                margin-bottom: 0.5em;
            `
        }
    }}

    border-radius: 1rem;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: ${props => props.theme.darkMode ? 'rgb(200, 200, 200)' : 'black'};

    ${props => {
        if (props.searched) {
            return `
                background-color: ${props.theme.darkMode ? 
                    'rgb(41, 41, 41, 1)' : 
                    'rgba(39, 120, 196, 0.3)'};

                &:hover {
                    background-color: ${props.theme.darkMode ? 
                        'rgba(39, 120, 196, 0.5)' : 
                        'rgba(39, 120, 196, 0.5)'};
                }
            `
        } else if (props.userIsSearching) {
            return `
                // background-color: transparent;
                // &:hover {
                //     background-color: transparent;
                // }
                
                background-color: transparent;
                &:hover {
                    background-color: ${props.theme.darkMode ? 
                        'rgb(41, 41, 41, 1)' : 
                        'rgba(0, 0, 0, 0.05)'};
                }

            `
        } else {
            return `
                background-color: transparent;
                &:hover {
                    background-color: ${props.theme.darkMode ? 
                        'rgb(41, 41, 41, 1)' : 
                        'rgba(0, 0, 0, 0.05)'};
                }
            `
        }
    }}


    @media (max-width: 500px) {
        // Terra de ninguém. A partir daqui, a chance de ocorrer wrap no texto
        // é significativa. Mas nem sempre acontece. Colocar uma height maior parece besteira

        height: auto;
        ${props => props.depth === 0 ? `
            padding: 1em !important;
            margin-bottom: 0.25em;
        `:`
            padding: 0.5em 0.75em 0.5em 0.25em !important;
            margin-bottom: 0.25em;
        `}

        // background-color: yellow;
    }



    @media (hover: none) {
        // O hover é horroroso e bugado no mobile, isso aqui desabilita
        &:hover {
            background-color: unset;
        }
    }

    

 
`





const Left = styled.div`
    ${RowCSS}
    width: 70%;
    height: 100%;

    // Este padding à esquerda é o que determina a aparente  
    // "profundidade" dos nós da árvore. Ele está aqui e 
    // não em container para fixarmos melhor a width.
    padding-left: ${props => props.depth * 1.75}rem;

    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);



    // background-color: cyan;

    @media (max-width: 850px) {
        padding-left: ${props => props.depth * 1.5}rem;
        width: 60%;
    }

    @media (max-width: 500px) {
        width: ${props => 100 - props.nStatus * 10}%;
    }
`


const Title = styled.p`
    font-size: 0.9rem;
    margin: 0 0 0 0.5rem;
    padding: 0;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'black'};

    ${props => props.depth === 0 && `
        font-weight: bold;
    `}

    @media (max-width: 850px) {
        font-size: 0.8rem;
    }

    @media (max-width: 500px) {
        // Sim, responsividade é uma merda, mas menos que 13px é 
        // oficialmente ilegível.
        font-size: 11px;
    }
`


const Right = styled.div`
    ${RowCSS}
    justify-content: flex-end;

    // Complementa a % de Left
    width: 30%;
    height: 100%;

    // debug
    // background-color: #75ff9a;

    @media (max-width: 850px) {
        // Complementa a % de Left
        width: 40%;
    }

    @media (max-width: 500px) {
        // Artificialmente pobre, raramente vai dar BO, até porque os campos mais largos
        // são os das TAG_1, onde o texto é mínimo e a indentação 0
        // 
        // É definido dinamicamente em função do número de status

        width: ${props => props.nStatus * 10}%;
    }
`


const Status = styled.div`
    // Cada <Status> é um item de status, como "revisões pendentes" ou "novos testes" ou etc
    // É uma coluna porque, no depth == 0, há o ícone e o número em cima, e o label embaixo
    ${ColumnCSS}
    justify-content: center;
    align-items: flex-end;
    height: 100%;

    
    width: ${props => 100 / props.nStatus}%;
    max-width: 6em;

    // width: ${props => props.suggestedWidth}em;


    // border: 1px solid red;
    // background-color: yellow;
`


const Info = styled.div`
    // Só é relevante quando depth == 0, porque há o ícone.
    // ${RowCSS}
    // justify-content: flex-end;

    ${ColumnCSS}
    align-items: flex-end;

    width: 100%;


    @media (max-width: 850px) {
        ${ColumnCSS}
        align-items: flex-end;
    }
`


const Icon = styled.img`
    width: 1.5rem;


    @media (max-width: 850px) {
        width: 1.1rem;
        margin-right: 0;
    }
    
`


const StatusText = styled.p`
    color: ${props => {
        if (props.theme.darkMode) {
            if (props.color === 'gray') return 'rgb(180, 180, 180)'
            if (props.color === '#8E0011') return '#ff9999'
            if (props.color === '#006400') return '#90EE90'
            if (props.color === '#2778C4') return '#87CEEB'
            return props.color
        }
        return props.color
    }};

    margin: 0;
    padding: 0;
    text-align: right;
`


const Number = styled(StatusText)`
    font-size: 0.8rem;

    @media (max-width: 850px) {
        font-size: 0.75rem;
    }

    // Celulares, terra de ninguém
    @media (max-width: 500px) {
        // Sim, responsividade é uma merda, mas menos que 13px é 
        // oficialmente ilegível.
        font-size: 11px;
    }
`

const Label = styled(StatusText)`
    font-size: 0rem;
`


const Row = styled.div`
    display: flex;
    align-items: center;
`

const PlusSign = styled.p`
    font-size: 0.6rem;
    color: #aba9a9;
    margin: 0 0.25rem;
`

const NumberWrapper = styled.p`
    margin: 0;
    padding: 0;
`


export default function TreeNodeJSX({ node, mode, testType, userIsSearching, triggerTreeRender, visible, 
                                        onNodeChecked, config, screenWidth, treeFlags}) {

    const [status, setStatus] = useState([])
    const [filteredStatus, setFilteredStatus] = useState([])

    useEffect(() => {
        if (!node || !node.info || !node.available) return
       
        // A lógica mudou; agora, quando o nó não tem "testes relevantes",
        // ele sequer aparece, pois não é "available"
        // 
        // Ainda, antigamente, determinávamos se o nó é clicável em função do # de testes e dos filtros;
        // Hoje, tudo é clicável, mas ficou como legacy.
        const s = getStatus(mode, testType, node.info, config)
        setStatus(s)
        setFilteredStatus( s.filter(l => l && l.number && l.number > 0) )

    }, [node, mode, testType, config])


    function getStatus(mode, testType, infoDict, config) {
        const gray = 'black'
        const red = '#8E0011'
        const blue = '#2778C4'

        const info = {}
        for (const key in infoDict) {
            info[key] = infoDict[key].length
        }

        // console.log(infoDict)
        // console.log(info)

        if (mode === 'consult-mode' || mode === 'playground-mode') {
            const total = (
                (config.removeNewTests ? 0 : info.availableTests) +
                (config.removePendingReviews ? 0 : info.pendingReviews) +
                (config.removeFutureReviews ? 0 : info.futureReviews) +
                (config.removeSolved ? 0 : info.solved)
            );
            
            return [{
                icon: mode === 'consult-mode' ? Books : SandboxIcon,
                label: `${testType === 'Residencia' ? 'questões' : 'flashcards'} do tema`,
                number: total,
                color: gray,
                
            }]
        }
        else if (mode === 'clear-history') {            
            const seenTotal = info.pendingReviews + info.futureReviews + info.solved
            
            return [{
                icon: Dumbbell,
                label: `${testType === 'Residencia' ? 'questões' : 'flashcards'} já vistos`,
                number: seenTotal,
                color: red
            }]
        }
        else if (mode === 'test-mode') {

            const status = []

            // Solved
            if (!config.removeSolved && treeFlags.maxSolved > 0) {
                // if (info.solved > 0) {
                //     status.push({
                //         icon: Rocket,
                //         label: 'questões resolvidas',
                //         number: info.solved,
                //         color: blue
                //     })
                // } else if (treeFlags.maxSolved > 0) {
                //     status.push(false)
                // }

                status.push({
                    icon: Rocket,
                    label: 'questões resolvidas',
                    number: info.solved > 0 ? info.solved : false,
                    color: blue,
                    // width: getStatusWidth(treeFlags.maxSolved),
                })
            }

            // Reviews
            if ( (!config.removePendingReviews && treeFlags.maxPending > 0) || (!config.removeFutureReviews && treeFlags.maxFuture > 0) ) {
                const isPendingOnly = !config.removePendingReviews && config.removeFutureReviews
                const isFutureOnly = config.removePendingReviews && !config.removeFutureReviews
                const isBoth = !config.removePendingReviews && !config.removeFutureReviews
             
                const count = (isPendingOnly ? info.pendingReviews : 0) +
                             (isFutureOnly ? info.futureReviews : 0) +
                             (isBoth ? info.pendingReviews + info.futureReviews : 0)
             
                // if (count > 0) {
                //     status.push({
                //         icon: isPendingOnly ? Dumbbell :
                //               isFutureOnly ? Kettlebell :
                //               KettlebellDumbbell,
                //         label: isPendingOnly ? 'revisões pendentes' :
                //                isFutureOnly ? 'revisões futuras' :
                //                'revisões pendentes e futuras',
                //         number: count,
                //         color: red
                //     });
                // } else if (treeFlags.maxPending > 0 || treeFlags.maxFuture > 0) {
                //     status.push(false)
                // }

                status.push({
                    icon: isPendingOnly ? Dumbbell :
                              isFutureOnly ? Kettlebell :
                              KettlebellDumbbell,
                    label: isPendingOnly ? 'revisões pendentes' :
                            isFutureOnly ? 'revisões futuras' :
                            'revisões pendentes e futuras',
                    number: count > 0 ? count : false,
                    color: red,
                    // width: getStatusWidth(treeFlags.maxPending + treeFlags.maxFuture),
                })
             }
            
            // New tests/flashcards
            if (!config.removeNewTests && treeFlags.maxAvailable > 0) {

                // if (info.availableTests > 0) {
                //     status.push({
                        // icon: Notebook,
                        // label: `${testType === 'Residencia' ? 'questões novas' : 'flashcards novos'}`,
                        // number: info.availableTests,
                        // color: gray
                //     });
                // } else if (treeFlags.maxAvailable > 0) {
                //     status.push(false)
                // }

                status.push({
                    icon: Notebook,
                    label: `${testType === 'Residencia' ? 'questões novas' : 'flashcards novos'}`,
                    number: info.availableTests > 0 ? info.availableTests : false,
                    color: gray,
                    // width: getStatusWidth(treeFlags.maxAvailable),
                })
            }
            

            
            return status
        }
        return []
    }
    
    function newRender() {
        if (triggerTreeRender) {
            triggerTreeRender()
        }
    }


    function checkboxClicked() {
        node.changeIsChecked()
        if (onNodeChecked) {
            onNodeChecked()
        }

        newRender()
        console.log(node.info)
    }


    function collapseExpandClicked() {
        node.changeIsExpanded()
        newRender()
    }


    function textAreaClicked() {
        // Se o usuário clica sobre o texto, nós expandimos/colapsamos -- desde que não haja 
        // pesquisa ativa e existam filhos.
        //
        // Do contrário, se não há filhos (i.e., é último nó) ou existe pesquisa ativa,
        // clicar sobre o título ativa o checkbox.
        // if (!userIsSearching && node.children) {
        if (node.children) {
            collapseExpandClicked()
        }
        else {
            checkboxClicked()
        }
    }


    function renderStatus() {
        const formatNumber = num => isNaN(num) ? 0 : new Intl.NumberFormat('pt-BR').format(num)

        // console.log(`TreeNode: rendering status for ${node.title}, knowing screen width is ${screenWidth}`)
         // Renderização compacta quando a largura é menor que 500px
        //  const filteredStatus = status.filter(Boolean)

         if (screenWidth < 500) {
            // Renderização dos ícones com o "+" entre eles, se houver mais de um
            const icons = filteredStatus.length > 1 
                ? filteredStatus.map((item, index) => (
                    <React.Fragment key={index}>
                        <Icon src={item.icon} />
                        {index + 1 < filteredStatus.length && <PlusSign>+</PlusSign>}
                    </React.Fragment>
                  ))
                : <Icon src={filteredStatus[0]?.icon} />
    

            // Renderização de cada número separadamente com o componente <Number>
            const numbers = filteredStatus
            .filter(item => item.number > 0)
            .map((item, index, array) => (
                <>
                <Number key={index} depth={node.depth} color={item.color}>
                    {formatNumber(item.number)} 
                </Number>
                {index < array.length - 1 && <PlusSign>+</PlusSign>}
                </>
            ))
    
            return (
                <Status nStatus = {1}>
                    <Info>
                        {node.depth === 0 && <Row>{icons}</Row>}
                        <Row>{numbers}</Row>
                    </Info>
                </Status>
            )
        }



        // Atenção. Aqui, NÃO usamos filteredStatus, porque a ideia é inserir colunas
        // vazias mesmo, desde que QUALQUER OUTRO TreeNodeJSX as possua -- o que sabemos
        // através de treeFlags.
        return (
            <>
                {status.map((item, index) => (
                    item.number ? (
                        <Status nStatus = {status.length} suggestedWidth = {item.width}>
                            <Info>
                                {node.depth === 0 && <Icon src={item.icon} />}
                                {!item.hideNumber && (
                                    <Number 
                                        depth={node.depth} 
                                        color={item.color}
                                    >
                                        {formatNumber(item.number)}
                                    </Number>
                                )}
                            </Info>
                            {node.depth === 0 && 
                                <Label color={item.color}>
                                    {item.label}
                                </Label>
                            }
                        </Status>   
                    ) : (
                        <Status nStatus = {status.length} suggestedWidth = {item.width}>
                        </Status>
                    )
                ))}
            </>
        )
    }




    if (!node) return
    return (
        <AnimatePresence mode='sync'>
            {visible && (

                <Container    
                    key={node.id}
                    depth={node.depth}
                    onClick={textAreaClicked}
                    searched={node.searched}
                    userIsSearching={userIsSearching}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={{
                        hidden: { 
                            opacity: 0, 
                            scale: 0.8
                        },
                        visible: { 
                            opacity: 1, 
                            scale: 1 
                        }
                    }}
                    transition={{ 
                        duration: 0.2,
                        ease: "easeOut"
                    }}
                    whileHover={{ x: '0.4em' }}
                >
                    <Left hasChildren={node.children != undefined} depth = {node.depth} nStatus = {status.length}>
                        {node.children && !userIsSearching && (
                            <ExpandCollapse
                                expanded={node.isExpanded}
                                action={(e) => {
                                    e.stopPropagation()
                                    collapseExpandClicked()
                                }}
                            />
                        )}
                        { !node.children && !userIsSearching && 
                            <ExpandCollapse empty = {true} />
                        }
                        {true && (
                            <Checkbox
                                checked={node.isChecked}
                                mildlyChecked={node.isMildlyChecked}
                                action={(e) => {
                                    e.stopPropagation()
                                    checkboxClicked()
                                }}

                                // Margem adicional para facilitar click no mobile
                                style = {screenWidth <= 600 ? {marginLeft: '0.5em'} : null}
                            />
                        )}
                        <Title depth={node.depth}>{node.title}</Title>
                    </Left>
                    <Right depth={node.depth} nStatus = {status.length}>
                        {renderStatus()}
                    </Right>
                </Container>
            )}
        </AnimatePresence>
    )
}