import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { 
    Check
} from 'lucide-react'

// Assets da plataforma
import ResidenciaIcon from './../assets/residencia.png'
import FlashcardIcon from './../assets/flashcard.png'
import DumbbellIcon from './../assets/dumbbell.png'
import TitleCard from '../custom/TitleCard'


import Balloons from './../assets/tree/balloons.png'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import { PredefinedSessionConfig, SORT_MODES, STUDY_MODES, TEST_TYPES } from '../controllers/SessionConfig'
import { useSessionStarter } from '../utils/StartSessionMethods'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import { darkBackgroundQuintenaryColor, darkBackgroundQuarternaryColor, darkBackgroundTertiaryColor, darkModeGreen, darkTextPrimaryColor, primaryColor } from '../tests/FlashcardsStyles'
import MainScreenBoxBttn from './MainScreenBoxBttn'




const ReviewGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5em;
    padding-top: 2em;
`



// Modificar o componente PremiumReviews para incluir todas as variações
export default function PremiumReviews() {
    const { startNewSession } = useSessionStarter()
    const [nFlashcards, setN] = useState( UserReviewsInfo.info['Flashcards'].pendingReviews.length)
    const [nResidencia, setR] = useState( UserReviewsInfo.info['Residencia'].pendingReviews.length)


    function startReviews(testType) {

        const config = PredefinedSessionConfig.create({
            ordering: SORT_MODES.SHUFFLE
        })

        startNewSession({
            testType: testType,
            builder: 'predefined',
            sessionConfig: config,
            studyMode: STUDY_MODES.TEST_MODE,
            saveAsLastSession: false,
            testIDs: UserReviewsInfo.info[testType].pendingReviews
        })
    }


    return (
        <TitleCard
            style = {{width: 'auto'}}
            title='Revisões'
            icon={DumbbellIcon}>
            

            <ReviewGrid>

                <MainScreenBoxBttn
                    icon={FlashcardIcon}
                    count={nFlashcards}
                    clickable = {nFlashcards > 0}
                    label='cards pendentes'
                    onClick = {() => startReviews(TEST_TYPES.FLASHCARDS)} />

                <MainScreenBoxBttn
                    icon={ResidenciaIcon}
                    count={nResidencia}
                    clickable = {nResidencia > 0}
                    label='questões pendentes'
                    onClick = {() => startReviews(TEST_TYPES.RESIDENCIA)} />

                
            </ReviewGrid>
            
        </TitleCard>
    )
}