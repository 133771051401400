import React from 'react';

import './FAQ.scss';

import { Link } from 'react-router-dom';

import { htmlToReact } from './../utils/HtmlToReact'
import SectionTitle from './SectionTitle';

export default function FAQ() {
    const questions = [
        ["Qual o risco de assinar?", (<div><p><strong>O risco é zero:</strong> nosso sistema de pagamento é seguro (Stone) e você tem 7 dias de garantia de reembolso integral. Basta mandar um e-mail para suporte@osler-ensino.com que fazemos seu estorno, rapidamente!</p></div>)],


        ["O acesso é por 12 meses?", (<div><p>Não. O acesso é até 31 de Janeiro de algum ano subsequente, a depender do plano que você adquiriu.</p></div>)],


        ["Há a possibilidade criar um plano de 12 meses, independentemente de quando eu comprar?", (<div><p>Não! Nós vendemos acesso até uma data futura fixa. Isso permite a padronização dos contratos e igualdade entre consumidores, e facilita o fluxo de renovação.</p></div>)],


        ["Há a possibilidade de 'parcelamento inteligente' ou boletos mensais?", (<div><p>Não! Por questões técnicas e de cunho comercial, estamos restritos ao pagamento por boleto à vista ou parcelamento no cartão em até 12x (o que leva a "consumo de limite").</p></div>)],


        ["É um aplicativo? Onde eu uso?", (<div><p>A plataforma é acessada online, através do seu computador, tablet, ou celular. <u>Não</u> é um aplicativo da loja: você acessa tudo através de nosso site.</p></div>)],
        

        ["É apenas para quem está prestando residência?", (<div><p>De modo algum! Embora a maioria dos nossos clientes estejam se preparando para a prova, muitos estão no ciclo clínico ou no começo do internato.</p></div>)],


        ["Criei uma conta e não chegou o email de confirmação.", (<div><p>Ou está na caixa de spam (verifique!) ou você digitou seu email errado durante o cadastro (tente sair e entrar novamente e verifique se o email está correto).</p></div>)], 


        ["Tem teste gratuito?", (<div><p>Não! Por questões de segurança, só há acesso após o pagamento. Mas, se você comprar, poderá testar tudo livremente. Se não se adaptar, peça cancelamento nos primeiros 7 dias e terá estorno do pagamento!</p></div>)],


        ["Quais os Termos de Uso?", (<div>Ao assinar e utilizar a Osler, você está de acordo com os Termos de Uso e Contrato de Prestação de Serviços, igual para todos os alunos. Para ter acesso, <Link to = 'https://firebasestorage.googleapis.com/v0/b/osler-web-app.appspot.com/o/Termos%20de%20Uso%20-%20Osler.docx.pdf?alt=media&token=05cc470b-9265-464b-9195-d22ac350893a' target="_blank" rel="noopener noreferrer">clique aqui!</Link></div>)], 
    ]

    const formatted = questions.map( (question, index) => {
        return (
            <div className="question-div" key={index}>
                <h4 className="faq-question">{ htmlToReact(question[0]) }</h4>
                { question[1] }
            </div>
        )
    })

    return (
        <div className="section section-dark-bg faq-container">
            <SectionTitle subtitle="FAQ" title="Suas perguntas, respondidas." />
            <div className="question-holder">
            {formatted}
            </div>
        </div>
    )


}

