import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import SideBar from './SideBar'
import TopBar from './TopBar'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles'
import NotebookSlide from '../tests/NotebookSlide'
import { useDispatch, useSelector } from 'react-redux'
import { setShowNotebook, setShowSidebar } from '../redux/notebookSlice'
import { ColumnCSS, OslerScrollBar } from '../components/BasicComponents'
import { useWindowWidth } from '../components/useWindowWidth'
import SimpleBar from 'simplebar'



const ScrollableContent = styled.div`
    ${ColumnCSS}

    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    // Remove a borda azul que surge ao focus()
    outline: none;

    ${backgroundWhiteBlack}


    // Parece besteira, mas evita com que, ao scroll, ele "pare" artificialmente
    // em alguns componentes.
    scroll-snap-type: none;
    scroll-behavior: auto;
    overscroll-behavior: none;

    ${OslerScrollBar()}
`


const StyledAppContainer = styled.div`
    display: flex;

    flex-direction: ${props => !props.sideBarAvailable ? `column` : `row`};

    justify-content: flex-start;
    align-items: stretch;

    width: 100vw;
    max-width: 100vw !important;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }

    // PRECISA ser isso, se não a side bar não estica
    min-height: 100vh;
    
    ${backgroundWhiteBlack}   
`;





const AppWrap = styled.div`
    display: flex;
    flex-direction: column;

    // FUNDAMENTAL que seja flex-start, senão oque fizemos do height e do scroll
    // acima dá merda
    justify-content: flex-start;
    align-items: center;


    ${backgroundWhiteBlack}


    // Tudo isso daqui é para o notebook slide.
    width: ${(props) => {
        if (props.isTouchDevice || props.screenWidth <= 600) {
            return '100%'
        }


        let width = '100vw'

        if (props.showSideBar) {
            width += (props.showingSideBar ? ' - 170px' : ' - 60px')
        }
        
        width += (props.showingNotebook ? ' - 35vw' : '')

        return `calc(${width})`
    }};



    max-width: ${(props) => {
        let width = '100vw'

        if (props.showSideBar) {
            width += (props.showingSideBar ? ' - 170px' : ' - 60px')
        }
        
        width += (props.showingNotebook ? ' - 35vw' : '')

        return `calc(${width})`
    }};

    transition: width 0.3s ease;

    @media (max-width: 500px) {
        width: 100%;

    }
`


export default function AppContainer({children, showSideBar = true}) {
   

    const [isDesktop, setDesktop] = useState(window.innerWidth > 500);
    const dispatch = useDispatch()
    const showNotebook = useSelector(state => state.notebook.showNotebook)
    const sideBarExpanded = useSelector(state => state.notebook.showSidebar)

    const scrollableRef = useRef(null);

    const [isTouchDevice, setIsTouchDevice] = useState(false)

    useEffect(() => {
        // Detecta se é um dispositivo touch
        const detectTouch = () => {
            const isTouch = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0 

            setIsTouchDevice(isTouch)
        }

        detectTouch()
        window.addEventListener('touchstart', detectTouch)
        return () => window.removeEventListener('touchstart', detectTouch)
    }, [])


    useEffect(() => {
        if (scrollableRef.current) {
          scrollableRef.current.focus()
        }
      }, [])


    const handleKeyDown = (event) => {
        if (event.code === 'Space' && event.target === scrollableRef.current) {
            event.preventDefault()
        }
    }


    const width = useWindowWidth()

    // const [sideBarAvailable, setSideBarAvailable] = useState(!window.matchMedia('(max-width: 600px)').matches && !('ontouchstart' in window))


    const contentRef = useRef(children)
    contentRef.current = children


    const sideBarAvailable = !isTouchDevice && width > 600


    const appWrapRef = useRef()

    const [scrollableContainerHeight, setContainerHeight] = useState(0)
    const handleMutations = useCallback((mutations) => {
        mutations.forEach(mutation => {
            if (appWrapRef.current) {
                const totalHeight = {
                    scrollHeight: appWrapRef.current.scrollHeight,
                    offsetHeight: appWrapRef.current.offsetHeight,
                    clientHeight: appWrapRef.current.clientHeight, 
                    boundingHeight: appWrapRef.current.getBoundingClientRect().height
                }
                
                setContainerHeight(totalHeight.scrollHeight)
            }
        })
    }, [])
    
    // Setup do MutationObserver
    useEffect(() => {
        if (!appWrapRef.current) return
    
        const observer = new MutationObserver(handleMutations)
        
        observer.observe(appWrapRef.current, {
            attributes: true,
            childList: true,
            subtree: true,
            attributeOldValue: true
        })
    
        return () => observer.disconnect()
    }, [handleMutations])


      return (
        // Definimos tabIndex = -1 no ScrollableContent para torná-lo focável via JavaScript.
        // Isso permite que chamemos scrollableRef.current.focus() e o elemento receba foco,
        // possibilitando a rolagem usando as teclas de seta do teclado sem que o usuário
        // precise clicar nele primeiro. Usar tabIndex = -1 garante que o elemento não seja
        // incluído na ordem de tabulação natural ao navegar com a tecla Tab, mantendo a
        // experiência de navegação por teclado intacta para os usuários.
        <ScrollableContent ref={scrollableRef} tabIndex="-1" onKeyDown={handleKeyDown} 
        >
            <StyledAppContainer sideBarAvailable = {sideBarAvailable} >

                { showSideBar && !sideBarAvailable &&
                    <TopBar />
                }

                { showSideBar && sideBarAvailable && <SideBar scrollableContainerHeight = {scrollableContainerHeight} /> }

                <AppWrap 
                    ref = {appWrapRef}
                    showingNotebook = {showNotebook}
                    sideBarExpanded = {sideBarExpanded}
                    screenWidth           = {width}
                    showSideBar     = {showSideBar && sideBarAvailable} >

                    
                    {contentRef.current}
                </AppWrap>

                <NotebookSlide
                    isOpen = {showNotebook}
                    close = {() => dispatch(setShowNotebook(false))} />

            </StyledAppContainer>
        </ScrollableContent>
    );
}