
import styled, { css } from 'styled-components'
import ShieldIcon from './../../assets/shield.png'
import { theme, fadeIn } from './CheckoutStyles'


import PagarMe from './../../assets/payment/pagarme.png'
import Stone   from './../../assets/payment/stone.png'
import { ColumnCSS, RowCSS } from '../../components/BasicComponents'


import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { setIsLoading } from '../../redux/loadingSlice'

import './CheckoutPage.css'

import { auth, functions } from '../../firebase/firebase-setup'

import MaskInput from 'react-maskinput';

import Select from 'react-select'

import InsertCoupon from './InsertCoupon'
import { sleep, toastMsg } from '../../utils/Utils'
import { Element, scroller } from 'react-scroll';
import OslerButton from '../../components/OslerButton'

import Door from './../../assets/door.png'
import { store } from '../../redux/store'
import { logOut } from '../../redux/userSlice'
import { Link, useNavigate } from 'react-router-dom'


const PaymentContainer = styled.div`
    ${ColumnCSS}
    flex: 1;
    padding: 2.5em;
    animation: ${fadeIn} 1s ease-out;

    // background-color: red;


    @media (max-width: 1200px) {
        // Precisa ter no bottom, para desgrudar o botão
        padding: 0 0 4em 0;
        // background-color: yellow;
    }

    @media (max-width: 600px) {
        // Precisa ter no bottom, para desgrudar o botão
        padding: 0 0 2em 0;
    }
`



const SecurePaymentBadge = styled.div`
    ${RowCSS}
    justify-content: space-between;
    width: 100%;

    padding: 1em 1.5em;
    background: ${theme.colors.secondary};
    border-radius: 0.5em;
    margin-bottom: 2em;

    .right {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    @media (max-width: 1200px) {
        justify-content: space-around;
        padding: 0em 5% 2em 5%;
    }

    @media (max-width: 600px) {
        padding: 1em 1em 2em 1em;
        
        .right {
            width: 100%;
            justify-content: center;
        }
    }
`


const PaymentIcon = styled.img`
    height: 3em;

    @media (max-width: 600px) {
        height: 3em;
    }
`

const WhoAreYou = styled.div`
    ${ColumnCSS}

 background: ${props => props.theme.darkMode ? 
        'rgba(255, 255, 255, 0.03)' : 
        'rgba(0, 0, 0, 0.02)'
    };
    
    padding: 1.25em;
    border-radius: 1em;
    margin: 0 2em 2em 2em;

    // Sombra interna sutil
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.02);

    p {
        margin: 0;
        color: ${props => props.theme.darkMode ? 
            'rgba(255, 255, 255, 0.8)' : 
            'rgba(0, 0, 0, 0.7)'
        };

        // O segundo parágrafo deve ser mais sutil
        &:nth-child(2) {
            text-align: center;
            margin: 0.5em 0 1em 0;
            color: ${props => props.theme.darkMode ? 
                'rgba(255, 255, 255, 0.6)' : 
                'rgba(0, 0, 0, 0.5)'
            };
            font-size: 0.95em;
        }
    }

    // Sutil animação no hover
    transition: all 0.2s ease;
    &:hover {
        background: ${props => props.theme.darkMode ? 
            'rgba(255, 255, 255, 0.04)' : 
            'rgba(0, 0, 0, 0.03)'
        };
    }

`


const MethodHelp = styled.p`
    margin: 0 0 0.5em 0;
    font-size: 0.9em;
    color: gray;
    text-align: center;
    font-weight: bold;
`

const PaymentMethods = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    margin-bottom: 2em;

    @media (max-width: 600px) {
        grid-template-columns: 1fr 1fr;
        gap: 0.8em;
        margin-bottom: 1.5em;
    }
`


const PaymentButton = styled.button`
    padding: 1em;
    border: 2px solid ${props => props.active ? theme.colors.primary : theme.colors.border};
    border-radius: 0.5em;
    background: ${props => props.active ? `${theme.colors.primary}10` : theme.colors.background};
    color: ${props => props.active ? theme.colors.primary : theme.colors.text.primary};
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: ${theme.fonts.weights.medium};

    &:hover {
        border-color: ${theme.colors.primary};
        transform: translateY(-2px);
    }
`;


const PaymentLeft = styled.div`
    ${RowCSS}
    widht: 30%;
    height: 100%;

    img {
        height: 2em;
    }

    p {
        margin: 0 0 0 0.5em;
        color: ${theme.colors.text.primary};
        font-weight: bold;
    }

    @media (max-width: 600px) {
        width: 100%;
        justify-content: center;
        
        img {
            height: 1.5em;
        }
    }

`



const PaymentForm = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    margin-top: 2em;

    .PaymentInfo-InputTxt {
        padding: 0.8em;
        border: 1px solid ${theme.colors.border};
        border-radius: 0.5em;
        font-size: 1em;
    }

    .PaymentInput-ChooseInstallments {
        margin-top: 1em;
    }

    @media (max-width: 1200px) {
        padding: 0 10% 0 10%;
    }


    @media (max-width: 600px) {
        margin-top: 0em;
        padding: 0em 2em 0em 2em;
    }
`

const InputDiv = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    gap: 0.1em;

    width: 100%;
`

const InputLabel = styled.label`
    font-weight: bold;
    font-size: 0.9em;
    color: gray;
`


const InputCSS = css`
    padding: 0.5em;
    margin: 0.3em 0 0 0em;
    border-radius: 0.5em;
    border: 1px solid gray;
    
    color: black;
    font-weight: bold;

    &::placeholder {
        font-weight: normal;  // placeholder será normal
        color: ${theme.colors.text.secondary};
    }


    min-width: 70%;
    font-size: 0.8em;

    @media (max-width: 600px) {
        min-width: 100%;
        font-size: 1em;
        padding: 0.8em;
    }

`

const MaskedInput = styled(MaskInput)`
    ${InputCSS}
`

const Input = styled.input`
    ${InputCSS}
`


const FormInformation = styled.div`
    ${ColumnCSS}
    width: 70%;
    align-items: flex-start;

    align-self: center;


    // background-color: yellow;

    @media (max-width: 900px) {
        width: 100%;
    }
` 


const Notices = styled.div`
    ${ColumnCSS}
    width: 100%;
    text-align: justify;

    @media (max-width: 600px) {
        p {
            font-size: 0.9em;
            margin-bottom: 0.8em;
        }
    }

`

const Form = styled.form`
    ${ColumnCSS}
    align-items: flex-start;
    align-self: flex-start;
    gap: 1em;
    margin: 1em 0;

    width: 70%;

    // border: 1px solid black;
    // background-color: pink;
    
    @media (max-width: 900px) {
        width: 100%;
    }
`



const CheckoutSummary = styled.div`
    margin-top: 2em;
    padding: 1em;
    background: ${theme.colors.background};
    border-radius: 0.5em;

    width: 70%;
    align-self: center;

    ul {
        margin-top: 1em;
        // list-style-type: none;
    }

    li {
        margin: 0.5em 0;
    }

    .CheckoutError {
        color: ${theme.colors.error};
    }

    background-color: #f0f3ff;

    @media (max-width: 600px) {
        width: 90%;

        ul {
            margin-left: 0em;
        }

        li {
            margin: 0.4em 0em 0.4em 0em;
        }
    }
`

const ErrorMessage = styled.div`
    margin: 2em 0;
    padding: 1em;
    background: ${theme.colors.error}10;
    border: 1px solid ${theme.colors.error};
    border-radius: 0.5em;

    .CheckoutError-Header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1em;
    }

    .CheckoutError-Title {
        font-weight: bold;
        font-size: 1.2em;
    }

    .CheckoutError-Close {
        cursor: pointer;
        padding: 0.5em;
    }

    @media (max-width: 600px) {
        margin: 2em 1em 1em 1em;

        .CheckoutError-Header {
            margin-bottom: 0em;
        }
    }
`

const ErrorP = styled.p`
    margin-top: 0em;

`

const ButtonContainer = styled.div`
    ${ColumnCSS}
    margin-top: 2em;
    
    @media (max-width: 600px) {
        margin-top: 0em;
        padding: 2em;
        width: 100%;
    }
`


const BuyButton = styled.button`
    background: linear-gradient(to right, #10b981, #059669);
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1.5rem 3rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    width: auto;
    position: relative;
    overflow: hidden;
    
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 5px 15px rgba(16, 185, 129, 0.3);
    }
    
    &:active {
        transform: translateY(0);
    }
    
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        background: linear-gradient(to right, #9ca3af, #6b7280);
        transform: none;
        box-shadow: none;
    }

    @media (max-width: 600px) {
        width: 100%;
        padding: 1.25rem;
        font-size: 1.25rem;
    }
`


const Disclaimer = styled.p`
    margin: 0 0 1em 0;
    padding: 0;
    width: 60%;
    text-align: center;
    font-size: 0.7rem;
`


export default function CheckoutPayment({plan = 'plan-1', onBuy, }) {


    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data)

    const [loaded, setLoaded] = useState(false)

    const [cardName, setCardName] = useState("")
	const [cardNumber, setCardNumber] = useState("")
    const [celular, setCelular] = useState("")
	const [CPF, setCPF] = useState("")
	const [expirationDate, setExpirationDate] = useState("")
	const [security, setSecurity] = useState("")
    const [cvvMask, setCvvMask] = useState("000")

    const [paymentMethod, setPaymentMethod] = useState(false)

    const [nInstallments, setInstallments] = useState()

    const [errorMessage, setErrorMessage] = useState(false)
    const coupon = useRef("")
    const planData = useRef(undefined)
    const [chosenInstallments, setChosenInstallments] = useState(false)

    const blockBuying = useRef(false)

    // O planID pode ser modificado se o usuário inserir um cupom
    // Mas nós guardamos o original para evitar o bug onde, ao
    // tentar confirmar o cupom ou inserir outro, não era reconhecido.
    const planID = useRef()
    const originalPlanID = useRef()

    const navigate = useNavigate()


    useEffect(() => {
        if (plan) {
            async function loadPlan() {
                dispatch(setIsLoading(true))

                planID.current = plan
                originalPlanID.current = plan
                
                const getPlans = functions.httpsCallable('getTransactionPlan')
                const response = await getPlans({ planID : planID.current})

                if (response && response.data) {
                    updatePlan(planID.current, response.data)
                    setLoaded(true)
                    dispatch(setIsLoading(false))
                }
                else {
                    // Não era para ocorrer, sugere que o plan está errado
                    createError(<ErrorP><b>Erro ao resgatar o plano.</b> Tente recarregar a página; qualquer coisa, envie uma DM.</ErrorP>)
                }
            }

            loadPlan()
        }
    }, [plan])



    function updatePlan(paramPlanID, paramPlanData, paramCouponCode = "") {
        planID.current = paramPlanID
        planData.current = paramPlanData
        coupon.current = paramCouponCode

        let options = []
        for (let i = 1; i <= planData.current.maxInstallments; i++) {
            options.push({
                value: `${i}`,
                label: getParcelasText(i)
            })
        }

        setInstallments( options )
    }


    function getReadablePrice() {
        return Math.round(planData.current.amount / 100).toLocaleString('pt-BR')
    }


    function getParcelasText(numberInstallments) {
        const price = planData.current.amount / 100
        const installmentPrice = Math.round(price / numberInstallments)
        const readablePrice = installmentPrice.toLocaleString('pt-BR')

        return (
            `${numberInstallments}x de R$${readablePrice}`
        )
    }


    async function buySubscription() {

        if (!paymentMethod) {
            createError(<ErrorP><b>Escolha um método de pagamento.</b></ErrorP>)
        }

        if (!isPersonalInfoValid()) {
            // Não criamos diálogo porque isso já é feito dentro da função.
            console.log("Dados pessoais inválidos")
            return;
        }

        // I.e., se for falso, se não está bloqueado
        // então permitimos a compra, mas bloqueamos
        // uma nova logo antes.
        if ( !blockBuying.current ) {
            blockBuying.current = true;
            if (paymentMethod === 'credit_card') {
                buyWithCard()
            }
            else {
                buyWithBoleto()
            }
        }
        else {
            // Isso nem deveria aparecer.
            createError(<ErrorP><b>Devagar, jovem.</b> Parece que você já tentou comprar. Cuidado para não clicar mais de uma vez e criar mais de uma transação por equívico.</ErrorP>)

            blockBuying.current = false;
        }
	}


    function isPersonalInfoValid() {
        if (!paymentMethod) {
			createError(<ErrorP><b>Escolha um modo de pagamento</b> Cartão (em até 12x) ou boleto (à vista), selecionando no topo.</ErrorP>)

            return false
        }
        else if (celular === "") {
			createError(<ErrorP><b>Preencha o celular.</b> Para evitar fraudes, o gateway de pagamento exige um celular váido.</ErrorP>)

            return false
		}
		else if (CPF === "") {
            createError(<ErrorP><b>Você não preencheu o CPF.</b> Lembre, precisa ser do comprador (i.e., do dono do cartão de crédito ou da conta corrente que pagará o boleto).</ErrorP>)

            return false
		}
        else if (CPF.length < 14) {
            // 11 digitos + 02 pontos + traço
            createError(<ErrorP><b>Verifique se o CPF está correto.</b></ErrorP>)
            return false;
        }
        else {
            return true
        }
    }


	function isCreditCardDataValid() {
		if (cardName === "") {
			createError(<ErrorP><b>Você não inseriu o <u>nome</u> do responsável pelo cartão!</b></ErrorP>)
			return false;
		}
		else if ( cardNumber.toString().length < 16) {
			createError(<ErrorP><b>Atente-se ao <u>número do cartão</u>.</b> Parece ter algo errado.</ErrorP>)
			return false;
		}
		else if ( expirationDate.toString().length < 4 ) {
			createError(<ErrorP><b>Atente-se ao <u>data de expiração</u> do cartão.</b></ErrorP>)
			return false;
		}
		else if ( security.toString().length < 3 ) {
			createError(<ErrorP><b>Atente-se ao <u>código de segurança</u> do cartão.</b></ErrorP>)
			return false;
		}
        else if (!chosenInstallments) {
            createError(<ErrorP><b>Escolha o número de parcelas.</b></ErrorP>)
            return false
        }
		else {
            const parts = expirationDate.split('/')
            const month = parseInt(parts[0])
            const year = parseInt(parts[1])

            console.log(month)
            console.log(year)
            
            if (month <= 0 || month > 12) {
                createError(<ErrorP><b>Mês de validade do cartão está errado.</b></ErrorP>)
                return false
            }
            else if (year < 23) {
                createError(<ErrorP><b>Ano de validade do cartão está errado.</b></ErrorP>)
                return false
            }
            else {
                return true
            }
		}
	}


	function createInput(label, value, setValue, mask, placeholder = "", type = 'number') { 
        return (
            <InputDiv>
                <InputLabel> { label } </InputLabel>
				    
                    { mask && 
                        <MaskedInput
                            onChange={ (event) => { setValue(event.target.value)}}
                            mask={mask}
                            maskString={mask}
                            value={value}
                            placeholder={placeholder}
                            type = {'tel'}/>
                    }

                    {/* Only for the NAME on the credit card */}
                    { !mask && 
                        <Input
                            placeholder = {placeholder}
                            type = {'text'}
                            value = { value.replace(/[0-9]+/g, '').toUpperCase() }
                            onChange = {(e) => setValue(e.target.value)}  />
                    }
					
            </InputDiv>
        )
	}


    async function buyWithBoleto() {
        if (!user || !user.firstName || !user.email || !user.lastName) {
            // TODO Change this for a modal?
            toastMsg('Não conseguimos identificar quem está logado. Saia e entre de novo. Se o problema persistir, contate nosso suporte.')
			return;
		}

        try {
            // TODO TUD OISSO QUE ESTAMOS TESTANDO EM RESULT.DATA.DATA NÃO EXISTE. NÃO ESTAMOS MANDANDO DE VOLTA. E NO CARTÃO DE CRÉDITO TAMBÉM NÃO.
            console.log("Blocking to buy subscription")
            dispatch(setIsLoading(true))

			const buyPlan = functions.httpsCallable('boletoTransaction')
			const result = await buyPlan({
                chosenPlan: planID.current,
                coupon: coupon.current,

                customer: {
                    external_id: user.id,
                    name: user.firstName + " " + user.lastName,
					email: user.email,
                    CPF: (CPF.replace(/[.]/g, '')).replace(/-/g, ''),
                    phone: celular.replace(/[(]/g, '').replace(/[)]/g, '').replace(/[ ]/g, '') 
				},
			});

            // Não temos garantia, mesmo que tenha ocorrido algum erro, que não houve
            // mudança do perfil do usuário (e.g., pagamento correto, mas algum crash
            // inexplicável do Firebase). Para ser redundante, verificamos alterações
            // no profile de qualquer jeito.

            // onBuy()


            dispatch(setIsLoading(false))
            if (result.data.success) {
                console.log('boleto: Chamando onBuy()')
                onBuy()
                console.log(result)
            }
            else {
				console.log('buySubscription(): error')
                console.log(result)

                createError(<><p><b>Seu pagamento foi recusado.</b> O que é muito estranho, pois você está pagando com boleto. Por favor, tire um print e envie para o suporte.</p><p>Mais informações: {JSON.stringify(result.data)} </p></>)
			}

            blockBuying.current = false;
            
        } catch (error) {
            onBuy()

            dispatch(setIsLoading(false))

            console.log(error)

            createError(<><p><b>Seu pagamento foi recusado.</b> Por favor, confirme suas informações com calma, e envie uma foto dessa tela para o suporte se não conseguir resolver.</p><p>Mais informações: {JSON.stringify(error)} </p></>)

            blockBuying.current = false;
        }
    }



    async function buyWithCard() {
        if (!isCreditCardDataValid()) {
            console.log("Dados do cartão inválidos")
            blockBuying.current = false;
            return;
        }
        
		if (!user || !user.firstName || !user.email || !user.lastName) {
            // TODO Change this for a modal?
            toastMsg('Não conseguimos identificar quem está logado. Saia e entre de novo. Se o problema persistir, contate nosso suporte.')
			return;
		}        
        
        try {
            console.log("Blocking to buy subscription")
            dispatch(setIsLoading(true))

			const buyPlan = functions.httpsCallable('creditCardTransaction')
			const result = await buyPlan({
                chosenPlan: planID.current,
                coupon: coupon.current,

                card_number: cardNumber.replace(/ /g, ''),
				card_cvv: security,
				card_expiration_date: expirationDate.replace(/[/]/g, ''), 
				card_holder_name: cardName,

                installments: chosenInstallments,

                customer: {
                    external_id: user.id,
                    name: user.firstName + " " + user.lastName,
					email: user.email,
                    CPF: (CPF.replace(/[.]/g, '')).replace(/-/g, ''),
                    phone: celular.replace(/[(]/g, '').replace(/[)]/g, '').replace(/[ ]/g, '') 
				},
			});

            dispatch(setIsLoading(false))

            /*
                O objeto result contém o objeto data, que contém os dados retornados pelo Firebase.
                Hoje, data contém os campos:
                    success - booleano - indica se a transação deu certo ou não
                    msg - string - uma mensagem explicando qual erro ocorreu, e que podemos mostrar ao usuário
                    code - string - uma codificação do tipo de erro, para identificarmos e tratarmos casos específicos
            */
            console.log(result)
            onBuy('credit_card', planData.current.amount, coupon.current)

            if (result.data.success) {
                console.log(result)
            }
            else {
				console.log('buySubscription(): error')
                console.log(result)

                // A realidade é que isso aqui raramente ocorre. Na maioria das vezes, vai para o pending payment (!)

                if (result.data.code === 'duplicated_transaction') {
                    createError(<><b>Parece que você já tentou comprar.</b> Para sua segurança, bloqueamos com o intuito de evitar uma transação duplicada. Recarregue a página e, se o erro persistir, envie um e-mail para suporte@osler-ensino.com que retornaremos em menos de 24 horas.</>)
                }
                else {
                    createError(<>
                        <p><b>Seu pagamento foi recusado.</b> Com cartão, é comum que ocorra. A seguir, as principais causas.</p>                
                        
                        <p><b>Tem limite?</b> É dura a vida do interno. Você precisa ter o limite do valor cheio. Se não tiver, é comum usar o cartão de algum familiar.</p>
    
                        <p><b>Você preencheu os dados do dono do cartão?</b> Lembre-se, precisamos do nome que está aparecendo no cartão, e do CPF dessa pessoa. Se o cartão é de um familiar, não adianta colocar seus dados.</p>
    
                        <p><b>O cartão é ELO?</b> Infelizmente, não é aceito.</p>
    
                        <p><b>O cartão bloqueou?</b> É relativamente comum o banco bloquear a transação por excesso de zelo. Ligue para eles.</p>
    
                        <p><b>Não é nada disso?</b> Mande um alô para a gente, via suporte@osler-ensino.com <u>enviando um print dessa tela</u>.</p>
    
                        <p>Mais informações: {JSON.stringify(result.data)} </p>
                    </>)
                }
			}

            blockBuying.current = false;
            
        } catch (error) {
            onBuy('credit_card', planData.current.amount, coupon.current)

            dispatch(setIsLoading(false))

            console.log(error)

            createError(<><p><b>Seu pagamento foi recusado.</b> Por favor, confirme suas informações com calma, e envie uma foto dessa tela para o suporte se não conseguir resolver.</p><p>Mais informações: {JSON.stringify(error)} </p></>)

            blockBuying.current = false;
        }
    }
	

	function checkCardNumber(cardNumber) {
		// Se é um AmericanExpress, o CVV precisa ter quatro dígitos.
		if (cardNumber.substring(0, 2) == '34' || cardNumber.substring(0, 2) == '37') {
			setCvvMask('0000')
		}
		else {
			setCvvMask('000')
		}
		setCardNumber(cardNumber)
	}


    function setMethod(chosenMethod) {
        setPaymentMethod(chosenMethod)
        createError(false)
    }


    function isAnyFieldEmpty() {
        return cardName == '' || cardNumber == '' || celular == '' || CPF == '' || expirationDate == '' || security == ''
    }

    
    function boletoIsAnyFieldEmpty() {
        return celular == '' || CPF == ''
    }


    function givenMethodFieldEmpty() {
        if (celular == '' || CPF == '') {
            return true
        }
        else if (paymentMethod === 'credit_card') {
            if (cardName == '' || cardNumber == '' || expirationDate == '' || security == '') {
                return true
            }
        }

        return false
    }


    // function goBack() {
    //     if (paymentMethod) {
    //         setMethod(false)
            
    //         console.log('SCROLLING')
    //         scroller.scrollTo('CheckoutPage-Top', {
    //             duration: 100,
    //             delay: 0,
    //             smooth: true,
    //             offset: -250, // Scrolls to element + 50 pixels down the page
    //           });
    //     }
    //     else {
    //         // Não era para ocorrer...
    //         createError(<ErrorP><b>Erro inesperado.</b> Tente recarregar a página; qualquer coisa, envie uma DM.</ErrorP>)

    //     }
    // }


    function createError(messageJSX) {
        if (!messageJSX) {
            setErrorMessage(false)
        }
        else {
            console.log('oi')
            setErrorMessage(messageJSX)
            scroller.scrollTo('CheckoutPage-Error', {
                duration: 100,
                delay: 0,
                smooth: true,
                offset: -50, // Scrolls to element + 50 pixels down the page
              });
        }
    }


    function changeUser() {
        auth.signOut()
        store.dispatch(logOut())
        navigate('/login')
    }

    return (
        <>
        { loaded && (
            <PaymentContainer>                
                <SecurePaymentBadge>
                    <PaymentLeft>
                        <img src={ShieldIcon} alt="Seguro" />
                        <p>Pagamento 100% seguro</p>
                    </PaymentLeft>
                    <div className="right">
                        <PaymentIcon src={PagarMe} alt="PagarMe" />
                        <PaymentIcon src={Stone} alt="Stone" />
                    </div>
                </SecurePaymentBadge>

                <WhoAreYou>
                    <p><b>Comprando na conta: <u>{user.email}</u></b></p>
                    <p>Se não é essa, faça logout.</p>

                    <OslerButton
                        img = {Door}
                        text = "Trocar de conta"
                        onClick = { changeUser }
                        size='small'/>
                </WhoAreYou>

                {/* Mantemos algo vazio mesmo após a escolha, para não causar deslocamentos estranhos */}
                <MethodHelp>{!paymentMethod ? 'Escolha seu método de pagamento...' : '\u00A0'}</MethodHelp>

                <PaymentMethods>
                    <PaymentButton 
                        active={paymentMethod === 'credit_card'} 
                        onClick={() => setMethod('credit_card')}>
                        Cartão de Crédito
                    </PaymentButton>
                    <PaymentButton 
                        active={paymentMethod === 'boleto'} 
                        onClick={() => setMethod('boleto')}>
                        Boleto Bancário
                    </PaymentButton>
                </PaymentMethods>


    
                <PaymentForm>
                    { paymentMethod === 'boleto' && (
                        <FormInformation>

                            <Notices>
                                <p>Pagamento à vista (<u>não</u> há opção de parcelar por boleto). Acesso após a compensação do pagamento (usualmente, 01 dia útil).</p>
                                <p><b>O CPF é do <u>titular da conta corrente</u> que será utilizada para pagar o boleto.</b> O celular pode ser o seu, é só para garantir uma via de contato além do e-mail.</p>
                            </Notices>

    
                            <Form>
                                {createInput('Seu celular', celular, setCelular, '+ 00 (00) 0 0000 0000', '+55 (DD) 9 0000 0000')}
                                {createInput('CPF de quem pagará', CPF, setCPF, '000.000.000-00', '000.000.000-00')}
                            </Form>
    
                            <InsertCoupon
                                originalPlanID={originalPlanID.current}
                                planID={planID.current}
                                changePlan={updatePlan} />
                        </FormInformation>
                    )}
    
                    { paymentMethod === 'credit_card' && (
                        <FormInformation>

                            <Notices>
                                <p>Pagamento à vista ou parcelado em <u>até</u> 12x, sem juros, usando o limite do cartão. Acesso imediato.</p>
                                <p><b>Atenção: os dados são do <u>titular do cartão</u>.</b> Se você está usando o cartão de um familiar, coloque o nome e o CPF dele. O celular pode ser o seu, é só para garantir uma via de contato além do e-mail.</p>

                            </Notices>
     
                            <Form>
                                {createInput('Seu celular', celular, setCelular, '+ 00 (00) 0 0000 0000', '+55 (DD) 9 0000 0000')}
                                {createInput('Nome que está no cartão', cardName, setCardName, null, 'WILLIAM OSLER')}
                                {createInput('CPF do titular do cartão', CPF, setCPF, '000.000.000-00', '000.000.000-00')}
                                {createInput('Número do cartão', cardNumber, checkCardNumber, '0000 0000 0000 0000', '4242 4242 4242 4242')}
                                {createInput('Data de vencimento', expirationDate, setExpirationDate, '00/00', 'MM/YY')}
                                {createInput('Código de segurança', security, setSecurity, cvvMask, 'CVV')}
                            </Form>
    
                            <InsertCoupon
                                originalPlanID={originalPlanID.current}
                                planID={planID.current}
                                changePlan={updatePlan} />
    
                            <div className="PaymentInput-ChooseInstallments">
                                <label className='BuySubscription-InputLabel'>À vista ou parcelado?</label>
                                <Select
                                    placeholder="Escolha o número de parcelas"
                                    options={nInstallments}
                                    onChange={e => {
                                        console.log(e)
                                        setChosenInstallments(Number(e.value))
                                    }} 
                                />
                            </div>
                        </FormInformation>
                    )}
                </PaymentForm>
    
                <CheckoutSummary>
                    <p><strong>DETALHES DA COMPRA</strong></p>
                    <ul>
                        <li>Acesso completo até <strong>{planData.current.validUntilFormatted}</strong>.</li>
    
                        {!paymentMethod && (
                            <li><strong className="CheckoutError">Escolha um método de pagamento.</strong></li>
                        )}
    
                        {paymentMethod === 'credit_card' && (
                            <li>Pagamento via cartão de crédito.</li>
                        )}
    
                        {paymentMethod === 'credit_card' && !chosenInstallments && isAnyFieldEmpty() && (
                            <li><strong className="CheckoutError">Insira seus dados e escolha o número de parcelas.</strong></li>
                        )}
    
                        {paymentMethod === 'credit_card' && chosenInstallments && isAnyFieldEmpty() && (
                            <li><strong className="CheckoutError">Falta inserir os dados.</strong></li>
                        )}
    
                        {paymentMethod === 'credit_card' && !chosenInstallments && !isAnyFieldEmpty() && (
                            <li><strong className="CheckoutError">Escolha o número de parcelas.</strong></li>
                        )}
    
                        {paymentMethod === 'credit_card' && chosenInstallments && (
                            <li><strong>{getParcelasText(chosenInstallments)}</strong> (total de <strong>R${getReadablePrice()}</strong>).</li>
                        )}
    
                        {paymentMethod === 'boleto' && (
                            <>
                            <li>Pagamento via boleto.</li>
                            <li>Valor <strong>à vista</strong> de <strong>R${getReadablePrice()}</strong>.</li>
                            </>
                        )}
                        
                        {paymentMethod === 'boleto' && boletoIsAnyFieldEmpty() && (
                            <li><strong className="CheckoutError">Falta inserir seus dados.</strong></li>
                        )}
                    </ul>
                </CheckoutSummary>
    
                {errorMessage && (
                    <ErrorMessage>
                        <div className="CheckoutError-Header">
                            <div>
                                <p className="CheckoutError-Title">
                                    <span className="CheckoutError-Emoji">⚠️</span>
                                    Habemus quaestio
                                </p>
                                <p className="CheckoutError-Detail">Porque não basta dar problema, tem que ter Latim no meio.</p>
                            </div>
    
                            <div className="CheckoutError-Close" onClick={() => setErrorMessage(false)}>
                                X
                            </div>
                        </div>
    
                        {errorMessage}
                    </ErrorMessage>
                )}
    
                <ButtonContainer>    

                    <Disclaimer>Ao adquirir, você está de acordo com os <Link to = 'https://firebasestorage.googleapis.com/v0/b/osler-web-app.appspot.com/o/Termos%20de%20Uso%20-%20Osler.docx.pdf?alt=media&token=05cc470b-9265-464b-9195-d22ac350893a' target="_blank" rel="noopener noreferrer">termos de uso e contrato de prestação de serviços</Link>.</Disclaimer>
                    <BuyButton 
                        onClick={buySubscription}
                        disabled={givenMethodFieldEmpty() || !paymentMethod}>
                            Comprar agora
                    </BuyButton>
                </ButtonContainer>
    
                <Element name="CheckoutPage-Error" />
            </PaymentContainer>
        )}
        </>
    )
}