import React, { useState } from 'react'
import styled from 'styled-components'
import { ChevronRight, Languages, Minus, Plus } from 'lucide-react'
import Signature from './../assets/signature.png'
import SignatureDark from './../assets/signatureDark.png'
import { ColumnCSS } from '../components/BasicComponents'
import { useSelector } from 'react-redux'
import { darkBackgroundTertiaryColor, darkTextSecondaryColor, primaryColor } from '../tests/FlashcardsStyles'

const QuoteSection = styled.div`
    width: 100%;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    gap: 2em;

    @media (max-width: 900px) {
        padding: 1em;
    }
`

const TopRow = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    align-items: flex-start;
    gap: 0px
`

const Quote = styled.div`
    font-family: 'Fraunces', serif;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.01em;

    color: ${props => props.theme.darkMode ? '#ca4a4c' : '#a01d26'};
    flex: 1;

    &::before {
        content: '"';
        margin-right: 0.1em
    }

    &::after {
        content: '"';
        margin-left: 0.1em
    }

    @media (max-width: 900px) {
        font-size: 1.8rem;
    }

    @media (max-width: 600px) {
        font-size: 1.6rem;
    }

`

const Actions = styled.div`
    ${ColumnCSS}
    gap: 8px;
    align-self: center;

    // background: yellow;
    margin-left: 1em;
`

const IconButton = styled.button`
    background: transparent;
    border: 1px solid rgba(160, 29, 38, 0.15);

    ${primaryColor}

    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;


    background: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(160, 29, 38, 0.1)'};
    
    &:hover {
    background: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(160, 29, 38, 0.1)'};
        border-color: rgba(160, 29, 38, 0.3)
    }
`

const BottomRow = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: flex-end
`

const SignatureWrapper = styled.div`    
    img {
        height: 48px;
        filter: contrast(1.1);
        transition: transform 0.2s ease
    }

    &:hover img {
        transform: scale(1.02)
    }


`

const Discussion = styled.div`
    max-width: 800px;
    margin: 32px auto 0;
    padding: 2em;

    ${props => props.theme.darkMode ? `
        background: ${darkBackgroundTertiaryColor};
        color: ${darkTextSecondaryColor};
    `:`
        background: rgba(160, 29, 38, 0.04);
        color: #333;    
    `}



    border-radius: 1em;
    font-family: 'Source Serif Pro', Georgia, serif;
    font-size: 1.1rem;
    line-height: 1.6;

    @media (max-width: 900px) {
        font-size: 1rem;
    }

    @media (max-width: 600px) {
        font-size: 0.9rem;
    }

`

export default function MainScreenHeader() {
    const oslerQuotes = [
        {
            'en': 'Bury the past and start afresh today with the firm resolve to waste not an hour of the short and precious time which is before you.',
            'pt': 'Enterre o passado e recomece hoje, com a firme determinação de não perder uma única hora do curto e precioso tempo que você dispõe.',
            'explanation': '<p>Talvez não pareça, mas <b>Osler escreveu muito — muito mesmo — sobre ética de trabalho, produtividade, gerenciamento do tempo, atenção, rotina de estudos, etc.</b></p><p>Esta citação é explícita: não adianta se lamuriar pelo que já foi. Erros são esperados. Enterre o passado e recomece hoje, focando em não desperdiçar seu tempo.</p>',
            'ref': 'Quotable (102)'
        },
        {
            'en': 'Nothing much has been going on. I am very busy but shall slacken a little at Christmas.',
            'pt': 'Nada demais tem acontecido. Estou muito ocupado, mas vou relaxar um pouco durante o Natal.',
            'explanation': '<p>Foi assim que Osler encerrou uma carta para sua irmã, em Dezembro de 1872.</p> <p><b>Osler era um franco workaholic que literalmente defendia que a palavra chave na vida, e para o sucesso profissional e pessoal, era <u>trabalho</u>.</b></p><p>A despeito dessa ética de trabalho literalmente protestante (seu pai era um reverendo anglicano), ele se deu a liberdade de descansar durante o fim do ano, de pouco antes do Natal até o Ano Novo.</p><p>Então, reconheça estes momentos e vá descansar. Aproveite.</p><p><span style="font-size:0.75em;">(Disse ele, enquanto programava na manhã de Natal.)</span></p>',
            'ref': 'The Life of Sir William Osler (pg 98)'
        }
        // {
        //     'en': '',
        //     'pt': '',
        //     'explanation': '',
        //     'ref': ''
        // }
    ]

    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(() => {
        return Math.floor(Math.random() * oslerQuotes.length);
      })

    function handleNextQuote() {
        setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % oslerQuotes.length)
      }
    
    const [showPortuguese, setShowPortuguese] = useState(false)
    const [showDiscussion, setShowDiscussion] = useState(false)

    const currentQuote = oslerQuotes[currentQuoteIndex]
    const displayedQuote = showPortuguese ? currentQuote.pt : currentQuote.en

    const darkMode = useSelector(state => state.theme.darkModeOn)

    return (
        <QuoteSection>
            <TopRow>
                <Quote>{displayedQuote}</Quote>
                <Actions>
                    <IconButton 
                        onClick={() => setShowPortuguese(!showPortuguese)}
                        title={showPortuguese ? "Show in English" : "Mostrar em Português"}
                    >
                        <Languages size={16} />
                    </IconButton>

                    <IconButton onClick={() => setShowDiscussion(!showDiscussion)}>
                        {showDiscussion ? <Minus size={16} /> : <Plus size={16} />}
                    </IconButton>

                    <IconButton onClick={handleNextQuote} title="Próxima citação">
                        <ChevronRight size={16} />
                    </IconButton>


                </Actions>
            </TopRow>
            
            <BottomRow>
                <SignatureWrapper>
                    <img src={darkMode ? SignatureDark : Signature} alt="William Osler" />
                </SignatureWrapper>
            </BottomRow>
            
            {showDiscussion && (
                <Discussion dangerouslySetInnerHTML={{ __html: currentQuote.explanation }} />
            )}
        </QuoteSection>
    )
}