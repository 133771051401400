import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from '../redux/loadingSlice'
import { useNavigate } from 'react-router-dom'
import AppContainer from '../app-container/AppContainer'
import CardList from '../components/CardList'
import { sleep, sortIDsByTagpath, toastMsg } from '../utils/Utils'
import FlashcardIcon from '../assets/flashcard.png'
import MCQIcon from '../assets/residencia.png'
import './SavedTestsScreen.css'
import styled from 'styled-components'
import OslerButton from '../components/OslerButton'
import TileGrid, { prepareDataForTileData } from '../user/TileGrid'
import GeneralDialog from './GeneralDialog'
import { Column, ColumnCSS, Row, RowCSS } from '../components/BasicComponents'
import SandboxIcon from './../assets/sandbox.png'
import BackArrow from './../assets/left-arrow-black.png'
import SessionBuilder from '../controllers/SessionBuilder'
import Session from '../controllers/Session'
import ScreenContainerHeader from '../components/ScreenContainerHeader'
import OslerCard from '../components/OslerCard'
import { PredefinedSessionConfig, SORT_MODES } from '../controllers/SessionConfig'

const TabBttns = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`


const NoDataMsg = styled.p`
    font-size: 1.1em;
    align-self: center;
    margin-top: 2em;
    color: gray;
    font-style: italic;
    font-weight: bold;
    text-align: center;
`


const TestsColumn = styled.div`
    ${ColumnCSS}

    max-width: 900px;

    margin-top: 2em;
`

const TopBttns = styled.div`
    ${RowCSS}
    width: 90%;
    justify-content: space-between;
`


export default function SavedTestsScreen({selectedTests, updateLikedScreen, eraseTests,
                                            eraseMsg, title, icon, flashcardsMsg, residenciaMsg,
                                            offerPlayground = false, bttnCall}) {

    let navigate = useNavigate()
    const dispatch = useDispatch()

    // const [selectedTab, setSelectedTab] = useState('Flashcards')
    const isLoading = useSelector(state => state.loading.isLoading)
    const [themeSelected, setThemeSelected] = useState(false)
    const [themeSelectedTestsIDs, setThemeSelectedTestsIDs] = useState([])
    const [tileData, setTileData] = useState([])
    const [showDialog, setShowDialog] = useState(false)
    const darkMode = useSelector(state => state.theme.darkModeOn)
    const [idsByTagPath, setIdsByTagPath] = useState([])


    const [testType, setTestType] = useState('Flashcards')


    useEffect(() => {
        window.scrollTo(0,0); // Scrolla a tela até em cima
        loadData()
        // changeSelectedContent('Flashcards')
    }, [selectedTests])



    async function loadData() {
        // selectedTests é um dicionário que contém os tests_ids
        // dos flashcards e questões de residência que iremos exibir.
        //
        // O primeiro passo é organizar esses IDs por temas.
        console.log('SavedTestsScreen(): loading from scratch')
        cleanScreen()
    
        const newIdsByTagPath = {}
        const newData = {}
    
        for (const type of ['Flashcards', 'Residencia']) {
            newIdsByTagPath[type] = sortByTagpath(type)
    
            if (newIdsByTagPath[type]) {                
                newData[type] = prepareDataForTileData(
                    newIdsByTagPath[type],
                    (idsByTagPath, tagpath) => {
                        console.log(idsByTagPath)
                        console.log(tagpath)
                        loadCardList(idsByTagPath, tagpath)    
                    }
                )
                
                newData[type].sort((a, b) => a.title > b.title ? 1 : -1)
            }
        }
    
        setIdsByTagPath(newIdsByTagPath)
        setTileData(newData)
    }


    function sortByTagpath(testType) {
        // Agrupamos os IDs por tagpath, para facilitar a visualização.
        return sortIDsByTagpath(testType, selectedTests[testType])
    }


    
    // async function changeSelectedContent(selectedTestType) {
    //     setSelectedTab(selectedTestType)
    //     setThemeSelected(false)
    // }


    function loadCardList(idsByTagPath, tagpath) {
        const testsIDs = idsByTagPath[tagpath]
        setThemeSelectedTestsIDs(testsIDs)
        setThemeSelected(true)
    }


    function goBack() {
        if (themeSelected) {
            // Precisa resetar porque é usado para a deleçõa.
            cleanScreen()
            updateLikedScreen()
        }
        else {
            navigate('/user')
        }
    }


    function cleanScreen() {
        setThemeSelected(false)
        setShowDialog(false)
    }

    function eraseSelected() {
        if (themeSelected) {
            eraseTests(testType, themeSelectedTestsIDs)
        }
        else {
            const combinedArray = Object.values(idsByTagPath[testType]).flat()
            eraseTests(testType, combinedArray)
        }
        cleanScreen()
    }


    async function startSession() {
        dispatch(setIsLoading(true))

        const config = PredefinedSessionConfig.create({
            ordering: SORT_MODES.SORT,
            detachCousins: true
        })


        // SessionBuilder.start(
        //     testType,
        //     'predefined',
        //     'playground-mode',
        //     themeSelectedTestsIDs,
        //     config,
        //     false
        // )


        SessionBuilder.start({
            testType,
            builder: 'predefined',
            studyMode: 'playground-mode',
            testIDs: themeSelectedTestsIDs,
            sessionConfig: config,
            descriptor: title
         })

        if (Session.sessionSize > 0) {
            Session.addTemporaryListener(moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/test")
        dispatch(setIsLoading(false))
    }
    

    const getContent = (testType) => {
        if (themeSelected) {
            return (
                <TestsColumn>
                        <TopBttns>
                        <OslerButton
                                color =  'grey'
                                img = {BackArrow}
                                text = 'Voltar'
                                onClick={goBack} />
                                
                        <Row>
            
                            <OslerButton
                                color =  'blue'
                                img = {SandboxIcon}
                                text = 'Modo Playground'
                                // size = {'small'}
                                style = {{marginRight: '2em'}}
                                onClick={() => startSession('playground-mode')} />  

                            <OslerButton
                                color =  'red'
                                icon = '⚠️'
                                text = {bttnCall}
                                onClick={() => setShowDialog(true)} />
                        </Row>
                    </TopBttns>

                    <CardList
                        style = {{marginTop: '2em'}}
                        testType={testType}
                        testsIDs={themeSelectedTestsIDs}
                        showLike = {true}
                        showBury = {true}
                        showFeedback = {true}
                        descriptor = {title}
                    />
                    
                </TestsColumn>
            )
        }
        else if (tileData[testType] && tileData[testType].length > 0) {
            return (
                <OslerCard style = {{padding: '0em 1em 1.5em 1em'}}>

                    <OslerButton
                        color =  'red'
                        icon = '⚠️'
                        text = {bttnCall}
                        size = {'small'}
                        style = {{alignSelf: 'flex-end', marginTop: '2em'}}
                        onClick={() => setShowDialog(true)} />

                    <TileGrid
                        options = {tileData[testType]} />                        
                </OslerCard>
            )
        }
        else {
            return (
                <Column>
                    <NoDataMsg>{testType === 'Flashcards' ? flashcardsMsg : residenciaMsg}</NoDataMsg>
                </Column>
            )

        }
    }
    
    
    const tabs = [
        {
            id: 'Flashcards',
            label: 'Flashcards',
            icon: FlashcardIcon,
            content: getContent('Flashcards')
        },
        {
            id: 'Residencia',
            label: 'Residência',
            icon: MCQIcon,
            content: getContent('Residencia')
        },
    ]


    return (

        <>
            <GeneralDialog
                open = {showDialog}
                title = '⚠️ Pense bem!'
                onClose = {() => setShowDialog(false)}
                actions={[
                    { label: 'Cancelar', onClick: () => setShowDialog(false), style: 'neutral'},
                    { label: 'Prosseguir', onClick: () => eraseSelected(), style: 'destructive' },
                ]}>
                    {eraseMsg}
            </GeneralDialog>

            
            <ScreenContainerHeader
                title = {title} 
                icon = {icon}
                tabs = {tabs}
                onTabChange = {tabID => setTestType(tabID)} />

</>
    )
}