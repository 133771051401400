import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
    Tag,
} from 'lucide-react'



import ResidenciaIcon from './../assets/residencia.png'
import FlashcardIcon from './../assets/flashcard.png'
import { Column, ColumnCSS, RowCSS } from '../components/BasicComponents'
import LastSessionController from '../controllers/LastSessionController'
import { STUDY_MODES, TEST_TYPES } from '../controllers/SessionConfig'

import CalendarIcon from './../assets/calendar.png'
import LabelIcon from './../assets/label.png'
import NextIcon from './../assets/next_white.png'
import TagIcon from './../assets/tag_blue.png'
import TrashIcon from './../assets/trash.png'
import StarBlack from './../assets/star_black.png'
import StarColor from './../assets/star_color.png'

import ControlIcon from './../assets/control_copy.png'
import OwlIcon from './../assets/owlMarginless.png'
import SandboxIcon from './../assets/sandbox.png'
import BooksIcon from './../assets/books.png'
import { formatNumber, sleep } from '../utils/Utils'
import { startSavedSession, useSessionStarter } from '../utils/StartSessionMethods'
import { get } from 'react-scroll/modules/mixins/scroller'


import RightBlueLight from './../assets/right_blue_light.png'
import RightBlueDark from './../assets/right_blue_dark.png'
import GeneralDialog from '../tests/GeneralDialog'
import OslerButton from '../components/OslerButton'
import { darkBackgroundQuarternaryColor, darkBackgroundQuintenaryColor, darkBackgroundTertiaryColor, darkModeBlue, darkTextSecondaryColor, textWhiteBlack } from '../tests/FlashcardsStyles'


const Session = styled.div`
    ${RowCSS}
    width: 100%;

    // background: gray;
`


const SessionCard = styled.div`
    ${ColumnCSS}
    width: 100%;
    align-items: flex-start;


    border-radius: 1em;
    padding: 1.5em;

    transition: all 0.3s ease;
    position: relative;


    ${props => props.theme.darkMode ? `
        background: ${darkBackgroundTertiaryColor};
        border: 1px solid ${darkBackgroundQuarternaryColor};
        &:hover {
            transform: translateX(8px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            border-color: ${darkBackgroundQuintenaryColor};
        }            
    `:`
        background: white;
        border: 1px solid #e2e8f0;
        &:hover {
            transform: translateX(8px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            border-color: #90cdf4;
        }    
    `}


    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        bottom: 10px;
        width: 2px;
        background: ${props => props.theme.darkMode ? darkModeBlue : `#4299e1`};

        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }


    // background: lightgray;
    // background: #f5f5f5;
`

const SessionRight = styled.div`
    ${RowCSS}
    height: 100%;
    width: 100%;

    padding-right: 2em;

    margin-top: 1em;

    // background: cyan;
`

const TopRow = styled.div`
    ${RowCSS}
    justify-content: space-between;
    width: 100%;

    // background-color: yellow;
`

const ActionsRow = styled.div`
    ${RowCSS}

    gap: 0.5em;
    // background: red;
`


const Bttn = styled.div`
    ${ColumnCSS}

    width: 2em;
    cursor: pointer;

    border-radius: 0.25em;
    padding: 0.5em 1em 0.5em 1em;


    transform: 0.3s;
    &:hover {
        background-color: ${props => props.theme.darkMode ? darkBackgroundQuarternaryColor : '#f5f5f5'};
    }
`



const ClearBttn = styled(Bttn)`
`

const FavBttn = styled(Bttn)`
`

const StartBttn = styled.div`

`



const TitleRow = styled.div`
    ${RowCSS}
    gap: 12px;
    margin-bottom: 4px;


    // background: yellow;
`

const TitleIcon = styled.img`
    height: 2em;
    // background-color: red;
`

const Title = styled.p`
    font-weight: bold;
    
    
    ${textWhiteBlack}


    // background-color: 
    

    font-size: 1.25rem;
    line-height: 1;
    margin: 0;
    padding: 0;
`

const SessionMeta = styled.div`
    ${RowCSS}
    align-items: flex-start;
    gap: 0.75em;

    padding-left: 0em;
    width: 100%;


    @media (max-width: 600px) {
        ${ColumnCSS}
        align-items: flex-start;
        margin-top: 1em;
        gap: 0.25em;
    }
`

const SessionMetaItem = styled.div`
    ${RowCSS}
    gap: 6px;

    padding: 0.25em 0.25em 0.25em 0.25em;


`


const ClickableSessionMetaItem = styled(SessionMetaItem)`
    cursor: pointer;

    border-radius: 0.25em;

    transform: 0.3s;
    &:hover {
        background-color: ${props => props.theme.darkMode ? darkBackgroundQuarternaryColor : '#f5f5f5'};
    }
`


const MetaIcon = styled.img`
    height: 1.2em;
`

const MetaLabel = styled.p`
    font-size: 0.85rem;
    color: gray;
    font-weight: bold;
    margin: 0;
    padding: 0;
`



const SessionTags = styled.div`
    ${RowCSS}

    width: 100%;
    justify-content: center;

    padding: 1em;
    // border: 1px solid gray;
    // border-radius: 1em;
    // background: yellow;

    gap: 4px;
    flex-wrap: wrap;
`

const SessionTag = styled.span`
    font-size: 0.7rem;
    padding: 4px 12px;
    background: #ebf8ff;
    color: $#3182ce;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
`





const SessionDetails = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    margin-left: 0.6em;
`

const Detail = styled.p`
    font-size: 0.9rem;
    color: gray;
    margin: 0;
    padding: 0;
    width: 100%;
`



const IconContainer = styled.div`
    ${ColumnCSS}
`

const Icon = styled.img`
    height: 1.6em;
    // background: red;
`




const BttnIcon = styled.img`
    height: 1.25em;
`



export default function LastSessionCard({ sessionID, session, toggleFavorite, deleteSession }) {
    const { startSavedSession } = useSessionStarter()
    const [showThemes, setShowThemes] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)


    function getTestModeIcon(mode) {
        switch (mode) {
            case STUDY_MODES.TEST_MODE:
                return OwlIcon
            case STUDY_MODES.CONSULT_MODE:
                return BooksIcon
            case STUDY_MODES.PLAYGROUND_MODE:
                return SandboxIcon
            default:
                return OwlIcon
        }
    }


    function getTopTags(session) {
        const T = session.tags ?? []

        const topTags = T.slice(0, 10)

        if (T.length > 10) {
            topTags.push('(e outros temas)')
        }

        return topTags
    }


    function loadSession() {
        const lastSession = LastSessionController.getLastSession(sessionID)

        console.log(sessionID)
        console.log(lastSession)

        startSavedSession(lastSession)
    }


    function getRightElement(session) {

        if (session.mode === STUDY_MODES.TEST_MODE) {
            const answered = session.session.filter(test => test.answered).length
            const completed = (answered / session.session.length) * 100
            const percentage = Math.ceil(completed)

            const isCard = session.testType === TEST_TYPES.FLASHCARDS


            // No X de N testes feitos, não queremos saber a posição que o 
            // usuário estava, mas sim quantos testes ele de fato responde.
            const nTests = session.session.length
            const nAnswered = session.session.filter(item => item.answered === true).length
            const label = `${formatNumber(nAnswered)} de ${formatNumber(nTests)} ${ isCard ? 'cards' : 'questões'}`

            return (
                <>
                    <IconContainer>
                        <Icon src = {getTestModeIcon(session.mode)} />
                    </IconContainer>
                
                    <SessionDetails>
                        <Detail>{percentage}% concluído</Detail>
                        <Detail>{label}</Detail>
                    </SessionDetails>

                </>
            )
        }
        else {

            return (
                <IconContainer>
                    <Icon src = {getTestModeIcon(session.mode)} />
                </IconContainer>
            )
        }
    }
    

    function timestampToReadable(timestamp) {
        const date = timestamp.toDate ? timestamp.toDate() : timestamp
    
        const day = date.getDate()
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        
        const months = [
            'jan', 'fev', 'mar', 'abr', 'mai', 'jun',
            'jul', 'ago', 'set', 'out', 'nov', 'dez'
        ]
        
        const month = months[date.getMonth()]
        
        return `${hours}:${minutes} de ${day}/${month}`
    }


    function willDeleteSession() {
        setShowDeleteDialog(false)
        deleteSession(sessionID)
    }

    return (
        <> 
        <GeneralDialog
            open = {showDeleteDialog}
            icon = {TrashIcon}
            title = 'Apagar sessão'
            onClose = {() => setShowDeleteDialog(false)}
            actions={[
                { label: 'Voltar', onClick: () => setShowDeleteDialog(false), style: 'neutral'},
                { label: 'Apagar', onClick: () => willDeleteSession(sessionID), style: 'destructive'},
            ]} >

            <p>Você irá <u>apagar</u> o acesso rápido a essa sessão.</p>
            
            <p>Isso <u>não</u> modifica suas estatísticas, é só um atalho para voltar de onde estava.</p>

            </GeneralDialog>


        <Session>
            <SessionCard key={sessionID}>
                    {/* <SessionLeft> */}
                        <TopRow>
                            <TitleRow>
                                <TitleIcon src = {session.testType === TEST_TYPES.FLASHCARDS ? FlashcardIcon : ResidenciaIcon} alt={session.type} />
                                <Title>{session.mainTheme}</Title>
                            </TitleRow>

                            <ActionsRow>
                                <ClearBttn onClick = {() => setShowDeleteDialog(true)}>
                                    <BttnIcon src = {TrashIcon} />
                                </ClearBttn>

                                <FavBttn onClick = {() => toggleFavorite(sessionID) }>
                                    <BttnIcon src = {session.favorite ? StarColor : StarBlack} />
                                </FavBttn>

                            </ActionsRow>
                        </TopRow>

                            <SessionMeta>
                                <SessionMetaItem>
                                    <MetaIcon src={CalendarIcon} />
                                    <MetaLabel>{timestampToReadable(session.dateSaved)} </MetaLabel>
                                </SessionMetaItem>


                                <SessionMetaItem>
                                    <MetaIcon src={ControlIcon} />
                                    <MetaLabel> {session.descriptor} </MetaLabel>
                                </SessionMetaItem>


                                <ClickableSessionMetaItem onClick = {() => setShowThemes(!showThemes)}>
                                    <MetaIcon src = { TagIcon } />
                                    <MetaLabel> { session.tags.length} temas </MetaLabel>
                                </ClickableSessionMetaItem>

                            </SessionMeta>

                                { showThemes && 
                                <SessionTags>
                                    { getTopTags(session).map((tag, index) => (
                                        <SessionTag key={index}>
                                            <Tag size={12} />
                                            {tag}
                                        </SessionTag>
                                    ))}
                                </SessionTags>                        
                                }

                <TopRow>

                    <SessionRight>
                        { getRightElement(session) }
                    </SessionRight>


                    <OslerButton
                        style = {{alignSelf: 'flex-end'}}
                        img   = {NextIcon}
                        color = 'blue'
                        iconRight = {true}
                        text = 'Retomar' 
                        onClick={loadSession}/>


                </TopRow>

            </SessionCard>

        </Session>

        </>
    )
}