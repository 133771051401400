import { createEmptyCard, default_w, FSRS, Rating, State } from "ts-fsrs";
import { getDateMidnight, isToday, sameDay } from "../utils/Utils";




const RATING = {
    // Padrão FSRS
    AGAIN: 1,
    HARD: 2,
    GOOD: 3,
    EASY: 4
}


// State
//  New: 0
//  Learning: 1
//  Review: 2
//  Relearning: 3


class OslerFSRS {
    constructor() {
        // Configurado para a meta de reter 90% dos flashcards, e com um intervalo
        // máximo de ~6 meses.
        this.fsrs = new FSRS({
            request_retention: 0.95,
            maximum_interval: 365 / 2
        })


        // Para o FSRS em si, o cálculo da próxima revisão só depende do objeto Card.
        // O ReviewLog só é importante para fins estatísticos e de reverter revisões.
        // Porém, precisamos de ambos devido aos learning steps.
        //
        // Então, nos valemos de um objeto Flashcard, que contém um objeto Card e 
        // um array de ReviewLogs.
        this.flashcard = undefined


        // Learning steps conforme padronizamos.
        this.learningSteps = [
            { 
                // 5 min | 30 min | 1 dia | 4 dias
                [RATING.AGAIN]: 5,
                [RATING.HARD]: 30,
                [RATING.GOOD]: 60 * 24 * 1,
                [RATING.EASY]: 60 * 24 * 4
            },
            {
                // 5 min | 1 dia | FSRS | FSRS  
                [RATING.AGAIN]: 5,
                [RATING.HARD]: 60 * 24 * 1,
                [RATING.GOOD]: 60 * 24 * 2,
                [RATING.EASY]: 60 * 24 * 6
                // [RATING.GOOD]: false,
                // [RATING.EASY]: false
            }
        ]
    }


    createNewFlashcard() {
        this.flashcard = {
            'card' : createEmptyCard(),
            'log' : []
        }

        return this.flashcard
    }


    createFlashcardFromFirebase(testStatistics) {
        // No Firestore, para cada flashcard, há um documento que contém o objeto Card() (dicionário)
        // e o array logs de ReviewLogs.
        //
        // Porém, as datas estão como Timestamps, então precisamos convertê-las para Date.
        //
        // Isso se, e somente se, o flashcard já foi respondido alguma vez.
        const convertTimestamp = (ts) => {
            // console.log('Tentando converter: ')
            // console.log(ts)
            return new Date(ts.seconds * 1000)
        }

        const robustConversion = (input) => {
            // Lixo. Sempre foi timestamp. Após a migração para o FSRS, por alguma cagada
            // virou string.
            if (typeof input === "string") {
                return new Date(input); // String ISO 8601
            } else if (input && typeof input.seconds === "number") {
                return new Date(input.seconds * 1000); // Timestamp
            }
            throw new Error("Formato de data inválido!");
        };
   
        const C = testStatistics.card

        if (C.last_review) {
            // Converte timestamps do card
            const card = {
                ...C,
                due: robustConversion(C.due),
                last_review: robustConversion(C.last_review) 
            }
        
            // Converte timestamps dos logs
            const log = testStatistics.log.map(entry => ({
                ...entry,
                due: robustConversion(entry.due),
                review: robustConversion(entry.review)
            }))
            
            this.flashcard = { card, log }
        }
        else {
            this.flashcard = testStatistics
        }
    

        // console.log(this.flashcard)

        return this.flashcard
    }




    getNextPossibleReviews(flashcard = this.flashcard, answerDate = new Date()) {
        // Presume flashcard como um objeto com Card e ReviewLog[], ambos com as 
        // datas já convertidas para objetos Date

        let schedules = this.fsrs.repeat(flashcard.card, answerDate)
        if (flashcard.card.state !== State.Review) {
            schedules = this.getLearningStepSchedules(flashcard, answerDate, schedules)
        }

        // // console.log('\n\n\n')
        // console.log(schedules)

        // Garantimos que todas as due dates são às 4am do dia. Afinal, é muito ruim
        // se a revisão só aparecer, por exemplo, às 15h, porque não permite oa usuário se planejar antes.
        // E também é ruim se elas aparecem às 00:00, como era na Osler, porque não permite zerar as revisões
        // de madrugada.
        for (const schedule of Object.values(schedules)) {
            // Se a data é hoje, não alteramos, obviamente.
            // Mas e se forem 23:50 e a próxima review é em 30min? Não será o mesmo dia...
            const scheduledForToday = sameDay(schedule.card.due, answerDate)
            const reviewIntervalMinutes = schedule.card.scheduled_days < 1

            // Na teoria, não precisava checar os dois, mas eu tenho certo receio de como scheduled_days
            // varia.
            if (!scheduledForToday && !reviewIntervalMinutes) {
                schedule.card.due.setHours(4, 0, 0, 0)
            }
        }

        return schedules
    }


    getLearningStepSchedules(flashcard = this.flashcard, answerDate = new Date(), schedules) {
        const currentState = flashcard.card.state
        const whichStep    = this.getLearningStep(flashcard)        

        for (const [rating, schedule] of Object.entries(schedules)) {
            // Para cada um dos possíveis objetos Card, em função do rating,
            // modificamos ou não certas variáveis.
            //
            // Iremos avaliar:
            //     due:               Quando o card deve ser revisado novamente
            //     scheduled_days:    Dias até próxima revisão programada
            //     stability:         Quão bem o card está memorizado (mais alto = mais estável)
            //     state:             Só graduamos para review após dois acertos


            // Se necessário, calculamos a nova due date e o scheduled_days.
            // Atenção ao detalhe: Nº step é o objeto de índice N-1 do array)

            const modifiedReviewInterval = this.learningSteps[whichStep - 1][rating]
            if (modifiedReviewInterval) {

                const data = this.getDueDateAndDaysDiff(modifiedReviewInterval, answerDate)

                schedule.card = {
                    ...schedule.card,
                    ...data
                }

                const elapsed_days = (answerDate - flashcard.card.last_review) / (1000 * 60 * 60 * 24)
                // console.log(`Mudamos due para: ${data.due}`)

                /*
                    Discussão infinita quanto ao que é o 'due' do log.
                    Eu achava que é quando "due date da última resposta". Mas olhando em 
                        https://open-spaced-repetition.github.io/ts-fsrs/example
                    Não parece ser. É sempre... da penúltima resposta.
                */
                schedule.log = {
                    ...schedule.log,
                    'elapsed_days' : elapsed_days || 0,
                    // 'due' : flashcard.card.due
                }
            }


            if (whichStep === 1) {
                // O valor inicial da Stability do card é determinado pelo rating da primeira
                // resposta.
                //
                // Nos default_weights do FSRS, os quatro primeiro elementos são, respectivamente,
                // a estabilidade inicial para cada rating (again, hard, good, easy).
                //
                // Hoje, FSRS-5: [0.40255, 1.18385, 3.173, 15.69105, ...]
                //
                // Consideramos que a estabilidade inicial de ~15 é discrepante, até porque
                // no FSRS-5, na primeira resposta, se easy, o scheduled_days é = 15, e aqui
                // é = 4.

                const initialStabilities = [
                    default_w[0],
                    default_w[1],
                    default_w[2],
                    4.0
                ]

                schedule.card.stability = initialStabilities[rating - 1]
            }


            // O FSRS não considera a progressão entre os learning steps
            // como nós. Por exemplo, se é Relearning, e o usuário acerta uma única vez,
            // ele já coloca como Review no que vem nos schedules.
            //
            // Então, precisamos corrigir tudo aqui.
            const remeberedCard = rating !== RATING.AGAIN
            switch (currentState) {
                case State.New:
                    // Eu acho que o FSRS já faz isso, mas enfim...
                    schedule.card.state = State.Learning
                    break

                case State.Learning:
                case State.Relearning:
                    if (whichStep === this.learningSteps.length && remeberedCard) {
                        schedule.card.state = State.Review
                    } else {
                        schedule.card.state = currentState
                    }
                    break

                case State.Review:
                    // Não sei se ocorre, é por redundância
                    schedule.card.state = State.Relearning
                    break
            }
        }


        // O FSRS já garante uma diferença de +1 dia entre a due date de cada rating. Mas aqui, devido
        // aos learning steps, não há especificamente no 2º step, entre hard e good.
        //
        // Abrindo espaço à possibilidade de mudanças dos learning steps, implementamos
        // uma solução genérica, para N steps e também entre good e easy.
        //
        // (Entre again e hard e good não é necessário, pois o 1º sempre é 5min, e o segundo
        // nunca será).
        //
        // Atente-se que schedules NÃO é um array, mas um dicionário com keys 1/2/3/4, então começamos
        // de i = 2
        for (let i = 2; i < 4; i++) {
            const cardA = schedules[i].card
            const cardB = schedules[i + 1].card
        
            while (cardB.due <= cardA.due) {
                cardB.due.setDate(cardB.due.getDate() + 1)
                cardB.scheduled_days += 1
            }
        }


        return schedules


    }


    getLearningStep(flashcard = this.flashcard) {
        // Retorna em qual learning step [1, N] o usuário *está*, para saber
        // quais intervalos utilizar.
        const {card, log} = flashcard

        if (card.state === State.New) {
            // Se é um card novo, intuitivamente está no 1º step.
            return 1                
        }
        else {
            // Avaliamos as últimas respostas, reunindo aquelas quando
            // o card ainda era New, Learning, ou Relearning.
            const learningEntries = []
            for (let i = log.length - 1; i >= 0; i--) {
                const entry = log[i]
                if (entry.state !== State.Review) {
                    // Adiciona no início para manter ordem cronológica
                    learningEntries.unshift(entry)
                } else {
                    break
                }
            }



            // Agora, revemos todas essas respostas, cronologicamente.
            // Sempre que o usuário acertou, ele avançou um step. Sempre
            // que errou, voltou ao 1º.
            let maxLearningSteps = this.learningSteps.length
            let learningStep = 1
            for (const entry of learningEntries) {
                if (entry.rating === Rating.Again) {
                    learningStep = 1
                } else {
                    if (learningStep < maxLearningSteps) {
                        learningStep += 1
                    }
                }
            }

            return learningStep            
        }
    }


    getDueDateAndDaysDiff(minutesInterval, answerDate = new Date()) {
        // const now = new Date()
        const targetDate = new Date(answerDate.getTime() + minutesInterval * 60000)
        const daysDiff = (targetDate - answerDate) / (1000 * 60 * 60 * 24)

        return {
            'due': targetDate,
            'scheduled_days': daysDiff
        }
    }


    getNextReviewForRating(flashcard = this.flashcard, rating, answerDate = new Date()) {
        // Onde rating é igual a 'Again', 'Hard', 'Good', ou 'Easy'
        const schedules = this.getNextPossibleReviews(flashcard, answerDate)
         

        const newFlashcard = schedules[Rating[rating]]
        return {
            'card' : newFlashcard.card,
            'log' : [...flashcard.log, newFlashcard.log]
        }
    }


    getReadableTimeUntilNextReview(flashcard = this.flashcard, startingDate = new Date()) {


        const schedules = this.getNextPossibleReviews(flashcard, startingDate)
        const nextReviewDates = Object.values(schedules).map(scheduleForRating => {
            return scheduleForRating.card.due
        })

        const midnight = getDateMidnight( startingDate )


        const readable = nextReviewDates.map(date => {
            const diffInMinutes = (date.getTime() - startingDate.getTime()) / (1000 * 60)

            
            if (diffInMinutes < 60 * 1) {
                // Se for menos de 1h, mostramos em minutos
                const minutes = Math.round(diffInMinutes)
                return minutes === 1 ? '1 minuto' : `${minutes} minutos`
            }
            else {
                // Se for mais de 24h, calculamos a diferença em dias usando meia-noite como referência
                const diffInDays = Math.round((date.getTime() - midnight.getTime()) / (1000 * 60 * 60 * 24))
                return diffInDays === 1 ? '1 dia' : `${diffInDays} dias`
            }
        })
    
        return readable
    }


}


export default new OslerFSRS()