// IdleNotification.js
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useIdleTimer } from 'react-idle-timer'
import session from '../controllers/Session'
import { useToast } from '../components/OslerToast'


const WarningWrapper = styled.div`
    height: 60px;
    max-width: 90%;
    margin-bottom: 1em;
`

const WarningContainer = styled.div`
   padding: 0.75rem 1rem;
   border-radius: 0.375rem;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   font-size: 1.1rem;
   display: flex;
   align-items: center;
   opacity: ${props => props.isIdle ? 1 : 0};
   transition: opacity 0.3s ease;
   
   ${props => props.severity === 'warning' ? `
       background: #fefcbf;
       border: 1px solid #f6e05e;
       color: #744210;
   ` : `
       background: #fde8e8;
       border: 1px solid #f98080;
       color: #c81e1e;
   `}
`

const Icon = styled.span`
   font-size: 1.4rem;
   margin-right: 12px;
`


export default function IdleNotification({ 
    onExit,
    secondsToIdle = 180,
    secondsToWarning = 240, 
    secondsToExit = 500 
 }) {
    const [isIdle, setIsIdle] = useState(false)
    const [timeUntilClose, setTimeUntilClose] = useState(null)
    


    const { addToast } = useToast()

    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * secondsToIdle,

        onIdle: () => {
            session.pauseStopwatches()
            setIsIdle(true)
        },

        onActive: () => {
            session.resumeStopwatches()
            setIsIdle(false)
            setTimeUntilClose(null)
            addToast('Voltamos a medir! ✅', {
                duration: 10000,  // 10 segundos
                position: 'BOTTOM'   // ou 'BOTTOM', 'TOP-RIGHT' (default)
             })
        }
    })
 
    useEffect(() => {
        let timer
        if (isIdle) {
            timer = setInterval(() => {
                const idleTime = Date.now() - getLastActiveTime()
                
                if (idleTime > 1000 * secondsToExit) {
                    onExit()
                } else if (idleTime > 1000 * secondsToWarning) {
                    setTimeUntilClose(secondsToExit - Math.floor(idleTime / 1000))
                }
            }, 1000)
        }
        return () => clearInterval(timer)
    }, [isIdle, onExit, secondsToWarning, secondsToExit])
 


    const [isMobile, setDesktop] = useState(window.innerWidth < 600);

    if (isMobile && !timeUntilClose) {
        return null
    }
   return (

        <WarningWrapper>
            <WarningContainer severity={timeUntilClose ? 'error' : 'warning'} isIdle={isIdle}>
                <Icon>{timeUntilClose ? '⚠️' : '⏱️'}</Icon>
                {timeUntilClose 
                    ? `Sessão será encerrada em ${timeUntilClose} segundos`
                    : 'Você está inativo, então paramos de medir o tempo.'}
            </WarningContainer>
        </WarningWrapper>
    )
}