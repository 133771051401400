import React, { useEffect, useMemo, useRef, useState } from 'react'
import './AmbassadorsDashboard.css'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from './../redux/loadingSlice'
import firebase from 'firebase/compat/app';
import AmbassadorsDashboardTables from './AmbassadorsDashboardTables';
import AmbassadorSearchBox from './AmbassadorSearchBox';
import styled from 'styled-components';
import InstitutionsDashboard from './InstitutionsDashboard';

import Logo from './../assets/Logo.png'
import Signature from './../assets/signature.png'
import AmbassadorOnboarding from './AmbassadorOnboarding';

import AmbassadorAdminView from './AmbassadorAdminView';
import AmbassadorsCount from './AmbassadorsCount';
import { sortMonths } from '../utils/Utils';


import { db, storage } from './../firebase/firebase-setup'
import AmbassadorsController from './AmbassadorsController';
import LastMonthComissionsStatus from './LastMonthComissionsStatus';
import { ColumnCSS, SelectableText } from '../components/BasicComponents';



const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #416296;

    width: 100%;
    min-height: 20vh;
`


const Footer = styled.div`
    background-color: white;
    padding: 2em 0 3em 0;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        height: 3em;
        margin-left: 1em;
        margin-right: 1em;
    }
`

const AmbassadorDashboardScreen = styled.div`
    ${ColumnCSS}
    width: 100%;
  align-items: flex-start;
  min-height: 100vh;
  padding-top: 4em;


    ${SelectableText}
`;

const EmbaixadoresHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EmbaixadoresLogo = styled.img`
  height: 8em;
  margin-right: 1em;
`;

const EmbaixadoresSignature = styled.img`
  height: 4em;
  margin-left: 1em;
`;

const EmbaixadoresTitle = styled.p`
  font-size: 2em;
  font-weight: bold;
  color: #164eab;
  margin: 0 0 0 1em;
  padding: 0;
`;

const EmbaixadoresDetail = styled.p`
    color: gray;
    font-size: 1em;
`


export default function AmbassadorsDashboard(props) {
    const user = useSelector(state => state.user.data)
    let navigate = useNavigate();
    const dispatch = useDispatch();


    // Se já carregamos os dados dem AmbassadorsControlled
    const [dataLoaded, setDataLoaded] = useState(false)

    // Embaixador selecionado pelo SearchBox, para o qual estamos exibindo ou
    // o AdminView ou a OnboardingView
    const [ambassador, setSelectedAmbassador] = useState(false)

    const [trigger, setTrigger] = useState(0)


    useEffect(() => {
        // TODO Colocar como algo do servidor, ao invés de deixar o uid público?
        if (user.id != '5TkhVQXzmaXBGaGC3sA1mT0upsq1') {
            console.log('Missing permission, mofo')
            navigate('/app')
        }

        loadData()
    }, [])


    useEffect(() => {
        console.log('nova renderização do pai')
    }, [triggerRender])


    function triggerRender() {
        setTrigger(trigger + 1)
    }


    async function loadAmbassadorData( uid ) {
        // A única merda que não está no 
        setSelectedAmbassador({
            ...AmbassadorsController.consolidatedProfiles.find(item => item.uid === uid),
            ...AmbassadorsController.registry[uid]
        })
    }


    function unselectAmbassador() {
        setSelectedAmbassador(false)
    }


    async function loadData( callBefore = undefined, removeAmbassador = false ) {
        dispatch(setIsLoading(true))
        // setDataLoaded(false)

        if (callBefore) {
            await callBefore()
        }

        await AmbassadorsController.init()

        if (removeAmbassador) {
            unselectAmbassador()
        }
        else if (ambassador) {
            loadAmbassadorData( ambassador['uid'] )
        }

        if (!dataLoaded) {
            setDataLoaded(true) 
        }
        
        triggerRender()        

        dispatch(setIsLoading(false))
    }


    async function approveAmbassador() {
        await loadData(
            () => AmbassadorsController.approveAmbassador(ambassador)
        )
    }


    async function cancelOnboarding() {
        await loadData(
            () => AmbassadorsController.cancelOnboarding(ambassador),
            true
        )
    }


    async function terminateAmbassador(termination_reason) {
        await loadData(
            () => AmbassadorsController.terminateAmbassador(ambassador, termination_reason)
        )
    }


    async function restituteAmbassador() {
        await loadData(
            () => AmbassadorsController.restituteAmbassador(ambassador)
        )
    }


    async function changeAmbassadorCoupon(nextCoupon) {
        await loadData(
            () => AmbassadorsController.changeCoupon(ambassador, nextCoupon)
        )
    }


    async function uploadInvoice(file, uid, selectedMonth) {
        await loadData(
            () => AmbassadorsController.uploadInvoice(file, uid, selectedMonth)
        )
    }


    async function removeInvoice(uid) {
        await loadData(
            () => AmbassadorsController.removeInvoice(uid)
        )
    }


    return (

        <AmbassadorDashboardScreen>
            <EmbaixadoresHeader>
                <LogoContainer>
                    <EmbaixadoresLogo src={Logo} alt="Logo" />
                    <EmbaixadoresSignature src={Signature} alt="Signature" />
                </LogoContainer>
                <EmbaixadoresTitle>Programa de Embaixadores</EmbaixadoresTitle>

                { dataLoaded && 
                    <EmbaixadoresDetail>Última atualização: {AmbassadorsController.consolidatedComissions['last_update']}</EmbaixadoresDetail>
                }
            </EmbaixadoresHeader>


        { !dataLoaded &&
            <p>Carregando...</p>
        }
        { dataLoaded && 
            <>
                <AmbassadorsCount />


                    <>
                        <AmbassadorsDashboardTables    
                            triggerRender={triggerRender} />
                    </>


                { !ambassador && 
                    <SearchContainer >
                        <AmbassadorSearchBox
                            selectAmbassador = {loadAmbassadorData} />
                    </SearchContainer>
                }
        
                { ambassador && ambassador['onboarding_complete'] &&
                    <AmbassadorAdminView                    
                        uploadInvoice = {uploadInvoice}
                        unselectAmbassador = {unselectAmbassador}
                        terminateAmbassador = {terminateAmbassador}
                        restituteAmbassador = {restituteAmbassador}
                        changeAmbassadorCoupon = {changeAmbassadorCoupon}
                        removeInvoice = {removeInvoice}


                        render = {trigger}
                        triggerRender={triggerRender}
                        ambassador = {ambassador}
                    />
                }
        
                { ambassador && !ambassador['onboarding_complete'] &&
                    <AmbassadorOnboarding
                        ambassador = {ambassador}
                        approveAmbassador = {approveAmbassador}
                        cancelOnboarding = {cancelOnboarding}
                        unselectAmbassador = {unselectAmbassador} />
                }
                
                <LastMonthComissionsStatus />
        
                <InstitutionsDashboard />
        
                <Footer>
                    <img src={Logo} />
                    <img src={Signature} />    
                </Footer>
            </>
        }
         
         </AmbassadorDashboardScreen>

    )
}