import React from "react";
import { useDispatch, useSelector } from "react-redux";

import LikedBuriedController from "../controllers/LikedBuriedController";
import SavedTestsScreen from "./SavedTestsScreen";

import LikedIcon from '../assets/save-color.png'
import LikedIconDark from '../assets/save-color-dark.png'

import { useEffect, useState } from "react";
import UserReviewsInfo from "../controllers/UserReviewsInfo";
import { useNavigate } from "react-router-dom";
import { setIsLoading } from "../redux/loadingSlice";


// Não parece razoa'vel que isso e buriedScreen não sejam a mesma coisa,
// generalizada. Porque eu só repeti o código, mesmo

export default function LikedScreen(props) {
    const [selectedTests, setTests] = useState(false)
    const navigate = useNavigate() 
    const darkMode = useSelector(state => state.theme.darkModeOn)
    const dispatch = useDispatch()


    useEffect(() => {
        if (UserReviewsInfo.isReady()) {
            update()

        }
        else {
            console.log("LikedScreen(): exiting to /app because data not ready")
            navigate('/app')
        }
    }, [])


    function update() {
        /*
            Quando você usa setTests(obj), o estado do React cria uma referência ao objeto obj. 
            Qualquer modificação subsequente em obj também refletirá diretamente no estado, 
            porque ambos compartilham a mesma referência de memória. Isso significa que mesmo 
            sem uma chamada explícita a setTests(), mudanças em obj podem inadvertidamente 
            alterar o estado, levando a comportamentos imprevistos e bugs difíceis de rastrear. 
            Para evitar isso, é essencial usar {...obj} para passar uma cópia superficial do objeto
            a setTests(), garantindo que o estado mantenha uma referência única e independente.
        */
        setTests({...LikedBuriedController.liked})
    }


    async function eraseTests(testType, listTests) {
        dispatch(setIsLoading(true))

        console.log(testType)
        console.log(listTests)

        await LikedBuriedController.likeOrDislikeAll(testType, listTests)
        update()
        
        dispatch(setIsLoading(false))
    }

    
    return (
       <>
       { selectedTests && 
            <SavedTestsScreen
                title = {'Testes salvos'}
                icon = {darkMode ? LikedIconDark : LikedIcon}

                selectedTests = { selectedTests }
                hideBury = {true}
                hideLike = {false}

                updateLikedScreen = {update}
                eraseTests = {eraseTests}

                bttnCall = 'Dessalvar todos'
                eraseMsg = {<><p><i>"Dessalvar" é uma palavra horrorosa; Tolkien revira-se no caixão gritando "CELLAR DOOR", e meu professor de literatura já dizia: "você não é bom o o suficiente para se permitir neologismos".</i></p><p>Se prosseguir, você irá remover <strong>todos</strong> estes testes da sua lista de salvos, e não será mais possível acessá-los por aqui.</p><p>Isso é <u>irreversível</u>.</p></>}
                
                offerPlayground = {true}
                
                flashcardsMsg = {'Você não tem flashcards salvos.'}
                residenciaMsg = {'Você não tem questões salvas.'} />   
        }
        </>
    )
}