

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Balloons from './../assets/tree/balloons.png'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import { darkBackgroundQuintenaryColor, darkBackgroundQuarternaryColor, darkBackgroundTertiaryColor, darkModeGreen, darkTextPrimaryColor, primaryColor } from '../tests/FlashcardsStyles'



const ReviewItem = styled.div`
    ${RowCSS}

    width: 100%;
    padding: 1.5em;

    gap: 1em;

    
    border: 1px solid ${props => props.theme.darkMode ? darkBackgroundQuarternaryColor : 'rgba(0, 102, 204, 0.3)'};
    background-color: ${props => props.theme.darkMode ? darkBackgroundTertiaryColor : 'rgba(0, 102, 204, 0.05)'};



    border-radius: 1em;


    cursor:     ${props => props.clickable ? `pointer` : `default`};

    // Determina a velocidade do hover na sequência
    transition: all 0.3s ease;

    ${props => props.theme.darkMode ? `
        &:hover {
            border: 1px solid ${darkBackgroundQuintenaryColor};
            background-color: ${darkBackgroundQuarternaryColor};
        }
    `:`
        &:hover {
            border: 1px solid rgba(0, 102, 204, 0.9);
            background-color: rgba(0, 102, 204, 0.1);
        }
    `}


    @media (max-width: 600px) {
        ${ColumnCSS}
    }
`


const IconWrapper = styled.div`
    ${RowCSS}    
    width: 20%;

    img {
        width: 3em;
        height: 3em;
        transition: transform 0.3s ease;
    }

    // Ao hover de ReviewItem, modifica a imagem
    ${ReviewItem}:hover & img {
        transform: scale(1.1) rotate(5deg);
    }

    @media (max-width: 600px) {
        width: 100%;
        justify-content: center;
    }
`

const TextContent = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 80%;
    text-align: left;

    @media (max-width: 600px) {
        width: 100%;
        align-items: center;
    }
`


const Text = styled.p`
    // Define a altura da linha como exatamente igual 
    // ao tamanho da fonte (100% da altura da fonte).
    line-height: 1;
    margin: 0;
    padding: 0;

    @media (max-width: 600px) {
        text-align: center;
    }
`

const Count = styled(Text)`
    font-size: 2rem;
    font-weight: bold;

    ${primaryColor}
`

const Label = styled(Text)`
    font-size: 1rem;
    color: ${props => props.theme.darkMode ? darkTextPrimaryColor : '#475569'};
`


const LabelCompleted = styled(Text)`
    font-size: 1rem;
    color: ${props => props.theme.darkMode ? darkModeGreen : '#16A34A'};
`

const BalloonIcon = styled.img`
    width: 2em;
    height: 2em;

    animation: float 3s ease-in-out infinite;
    @keyframes float {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-5px); }
    }
`



export default function MainScreenBoxBttn({ icon, count, clickable, label, onClick}) {


    function startSession() {
        if (clickable) {
            onClick()
        }
    }

    return (
        <ReviewItem onClick = {startSession} clickable = {clickable}>
            <IconWrapper>
                <img src={icon} alt={label} />
            </IconWrapper>

            {/* cuidado que count = 0 pode ser interpretado como undefined */}
            {typeof count === 'number' && (
                <TextContent>
                    {count === 0 ? (
                        <>
                        <BalloonIcon src={Balloons} alt="Celebração" />
                        <LabelCompleted><b>tudo feito!</b></LabelCompleted>
                        </>
                    ) : (
                        <>
                        <Count>{count}</Count>
                        <Label><b>{label}</b></Label>
                        </>
                    )}
                </TextContent>
            )}
        </ReviewItem>
    )
}
