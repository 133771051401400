/*

    Janeiro, 2024

    Objetivo: gerenciar o download, organização e persistência de todos os dados necessários
    para o funcionamento do aplicativo, exceção aos testes; baixo todos os dados em paralelo
    e os persistindo aqui, tornamos a plataforma mais rápida e localizamos gargalos.

    Dados da PLATAFORMA que precisam ser baixados:
        - Para flashcards & residência:
            metadata/${type_of_test}/summary/tag_count : quantidade de testes por tagpath

            metadata/${type_of_test}/summary/tag_hierarchy : hierarquia das tags

            metadata/{type_of_test}/tagpath_per_id/* : até X documentos, que indexam, para o 
            ID de dado teste, a tagpath do mesmo.

            metadata/{type_of_test}/tests_per_tagpath : até Y documentos que, para dado tagpath,
            dá o ID de todos os testes dele

            metadata/{type_of_test}/all_tests_ids : possui todos os testes que existem,
            para contrastarmos com as revisões do usuário, e detectarmos testes deletados.


        - Geral, para ambas:
            metadata/metadata : possui o número total de cards e de questões de residência

 
        - Exclusivo de residência:
            - Relação dos testes anulados, comentados & do extensivo. Respectivamente, em uma 
            coleção com três documentos que possuem: os ids de cada um, a contagem de quantos 
            por tagpath, e a relação de quais são para cada tagpath.

                metadata/Residencia/anuladas/
                    anuladas_ids
                    anuladas_tag_count
                    anuladas_per_tagpath
                
                metadata/Residencia/commented/
                    commented_ids
                    commented_tag_count
                    commented_per_tagpath

                metadata/Residencia/extensivo/
                    extensivo_ids
                    extensivo_tag_count
                    extensivo_per_tagpath


            - Relação da instituições para as quais temos provas, dos anos dos quais temos provas,
            e um mapeamento do nome legível da instituição para o ID curto dela (que fica no
            ID dos testes).
                metadata/Residencia/summary/institutions
                metadata/Residencia/summary/years
                metadata/Residencia/summary/institutions_IDs
    
    

    Dados do USUÁRIO que precisam ser baixados:
        Geral:
            /users/${this.userID}/personal/residencia_filters : filtros das provas de residência

        Para Flashcards & Residencia:

            - Dois documentos que indexam a data de todas as próximas revisões.
                /users/${this.userID}/${testType}/tests/reviews_indexed/
                    ms_per_id_0
                    ms_per_id_1
                    ms_per_id_2
                    ms_per_id_3

                /users/${this.userID}/personal/${testType.toLowerCase()}-buried : testes enterrados
                /users/${this.userID}/personal/${testType.toLowerCase()}-liked : testes salvo

    
    Proposta: não tem mistério, eu baixo todos os documentos -- sempre em paralelo, para otimizar
    -- e organizo eles em dicionários, fazendo algo muito próximo de um  espelhamento do que 
    tenho no Firebase, mas com ocasionais diferenças.
    
    Para os objetos que nós fragmentamos em vários, eu os sintetizo em um único novamente (e.g., 
    all_tests_ids será um único objeto, ao invés de termos um para cada um dos Y documentos).
*/

import { arraysHaveSameUniqueElements, measureTime } from '../utils/Utils';
    import { db } from './../firebase/firebase-setup'
import LikedBuriedController from './LikedBuriedController';


export const KEYS = {
    TAG_COUNT: 'tagCount',
    TAG_HIERARCHY : 'tagHierarchy',
    TAGPATH_PER_ID : 'tagpathPerId',
    TESTS_PER_TAGPATH : 'testsPerTagpath',
    ALL_TESTS_IDS : 'all_tests_ids',
    N_TOTAL_TESTS : 'nTotalTests',
    REVIEWS_INDEXED : 'reviewsIndexed',
    BURIED : 'buried',
    LIKED : 'liked',
    ALL_IDS : 'allIDs',
    PER_TAGPATH : 'perTagpath',
    COMENTADAS : 'comentadas',
    EXTENSIVO : 'extensivo',
    ANULADAS : 'anuladas',
    USER_FILTERS : 'userFilters',
    INSTITUTIONS : 'institutions',
    INSTITUTIONS_IDS : 'institutions_ids',
    EXAMS_METADATA : 'examsMetadata',
    YEARS : 'years',
    FLASHCARDS : 'Flashcards',
    RESIDENCIA : 'Residencia',
    TESTS_WITH_STICKIES : 'testsWithStickyNotes',
    MISTAKES_JOURNAL : 'mistakesJournal',
    NOTES: 'notes',
    NOTES_METADATA: 'notesMedatada',
    SOLVED: 'solved',
    // TESTS_LISTS : 'testsLists'
}

class OslerData {
    constructor() {

        const commonData = {
            [KEYS.TAG_COUNT]: undefined,
            [KEYS.TAG_HIERARCHY]: undefined,
            [KEYS.TAGPATH_PER_ID]: undefined,
            [KEYS.TESTS_PER_TAGPATH]: undefined,
            [KEYS.ALL_TESTS_IDS]: undefined,
            [KEYS.N_TOTAL_TESTS]: -1,
            [KEYS.REVIEWS_INDEXED]: undefined,
            [KEYS.BURIED]: undefined,
            [KEYS.LIKED]: undefined,
            [KEYS.TESTS_WITH_NOTES] : undefined
        };
        
        const residenciaLists = {
            [KEYS.ALL_IDS]: undefined,
            [KEYS.TAG_COUNT]: undefined, // Se este é um campo duplicado, talvez você queira reconsiderar a estrutura ou o nome.
            [KEYS.PER_TAGPATH]: undefined,
        }
        
        this.data = {
            [KEYS.RESIDENCIA]: {
                ...commonData,
                [KEYS.COMENTADAS]: residenciaLists,
                [KEYS.EXTENSIVO]: residenciaLists,
                [KEYS.ANULADAS]: residenciaLists,
                [KEYS.INSTITUTIONS] : undefined,
                [KEYS.YEARS] : undefined,
                [KEYS.USER_FILTERS]: undefined,
                [KEYS.MISTAKES_JOURNAL] : undefined,
                [KEYS.SOLVED] : undefined,
            },
        
            [KEYS.FLASHCARDS]: {
                ...commonData,
            },

            [KEYS.NOTES] : {
                [KEYS.NOTES_METADATA] : undefined
            }
        };

        // Precisamos guardar para baixar os dados específicos do usuário.
        this.userID = undefined

        // Guardamos a última vez que atualizamos, para forçar após determinado
        // período de tempo. E salvamos um statusChanged -- variável que permite sinalizar
        // para essa classe que algo está diferente, e devemos baixar os dados de novo.
        this.lastDownloadTime = Date.now();
        this.statusChanged = true;

        this.ready = false
    }


    signalStatusChange() {
        this.statusChanged = true;
    }


    async start(user, forceDownload = false) {
        this.userID = user.id

        


        if (this.shouldDownload() || forceDownload) {
            await measureTime(() => this.download(), '\tOslerData() loaded all data')
            this.statusChanged = false
            this.lastDownload = Date.now()
        }
        else {
            console.log(`OslerData: will NOT download updated data.\n
            Status changed? ${this.statusChanged} Last udpated? ${this.lastDownloadTime}`)

            console.log('However, will updated liked and buried.')
            this.updateLikedBuriedFromLocal()
        }

        this.ready = true
    }



    shouldDownload() {
        if (this.statusChanged) {
            console.log(`OslerData: will update because status changed.`)
            return true;
        }
        else {
            const timeSinceLastUpdate = Date.now() - this.lastDownload
            if (timeSinceLastUpdate > 5 * 60 * 1000) {
                // 5 minutos em milissegundos
                console.log(`OslerData: will update because >5min have passed.`)
                return true;
            }
        }
        return false;
    }





    updateLikedBuriedFromLocal() {
        // Cuidado com o bug. Sempre que um teste é respondido, OslerData.signalStatusChange()
        // é chamado, para garantir que os dados serão atualizados. Porém, se um usuário abre
        // uma sessão, e enterra N testes, sem responder nenhum, e então fecha a sessão, os dados
        // não serão atualizados, e depois serão sobrescritos ao carregar dados locais antigos.
        //
        // Isso é um cenário de exceção. A ideia aqui é não baixar tudo do zero, se não esperamos
        // mudanças significativas, e prezar pelos dados locais. Eu não sei até que ponto vale a pena
        // -- talvez deve ter um signalStatusChange() após cada statusChange de 
        // LikedBuriedController, e só fazer o download ser mais rápido...? Parece tão bug prone...
        if (!arraysHaveSameUniqueElements(this.data[KEYS.FLASHCARDS][KEYS.BURIED]['data'], LikedBuriedController.buried[KEYS.FLASHCARDS])) {
            console.log('\t Buried dos cards local foi modificado, e manteremos a versão nova.')
            this.data[KEYS.FLASHCARDS][KEYS.BURIED]['data'] = LikedBuriedController.buried[KEYS.FLASHCARDS]
        }

        if (!arraysHaveSameUniqueElements(this.data[KEYS.RESIDENCIA][KEYS.BURIED]['data'], LikedBuriedController.buried[KEYS.RESIDENCIA])) {
            console.log('\t Buried da residencia local foi modificado, e manteremos a versão nova.')
            this.data[KEYS.RESIDENCIA][KEYS.BURIED]['data'] = LikedBuriedController.buried[KEYS.RESIDENCIA]
        }

        if (!arraysHaveSameUniqueElements(this.data[KEYS.FLASHCARDS][KEYS.LIKED]['data'], LikedBuriedController.liked[KEYS.FLASHCARDS])) {
            console.log('\t Liked dos cards local foi modificado, e manteremos a versão nova.')
            this.data[KEYS.FLASHCARDS][KEYS.LIKED]['data'] = LikedBuriedController.liked[KEYS.FLASHCARDS]
        }   

        if (!arraysHaveSameUniqueElements(this.data[KEYS.RESIDENCIA][KEYS.LIKED]['data'], LikedBuriedController.liked[KEYS.RESIDENCIA])) {
            console.log('\t Liked da residência local foi modificado, e manteremos a versão nova.')
            this.data[KEYS.RESIDENCIA][KEYS.LIKED]['data'] = LikedBuriedController.liked[KEYS.RESIDENCIA]
        }
    }


    async download() {
        // Atenção. Quando executamos em paralelo, performance.now() deixa de funcionar,
        // e só iremos mensurar a execução do processo mais lento. Não parece haver work-around.
        // 
        // Se você quer saber o tempo individual de  cada função, a solução é tirar temporariamente
        // do Promise.all(), executar, e avaliar.
        //
        // Junho/2023, servidor em São Paulo, para um top user:
        //      as revisões demoram ~250 a 400ms
        //      docs individuais demoram ~60 a 100ms
        //
        // Porém, quando eu agrupo tudo em um Promise.all, a execução inteira demora ~450 a 500ms.
        // Não sei porque acaba demorando 50 a 100ms a mais, mas ainda assim compensa: baixar tudo
        // linearmente demora 2-3x mais!!

        await Promise.all([
            this.downloadTestTypeData('Flashcards'),
            this.downloadTestTypeData('Residencia'),
            this.downloadResidenciaSpecificData(),
            this.downloadMetadata(),
            this.downloadUserData(),
        ])    
    }


    async downloadTestTypeData(testType) {
        let promises = [
            db.doc(`metadata/${testType}/summary/tag_count`).get(),
            db.doc(`metadata/${testType}/summary/tag_hierarchy`).get(),
            db.collection(`metadata/${testType}/tests_per_tagpath/`).get(),
            db.collection(`metadata/${testType}/all_tests_ids`).get(),
            db.collection(`metadata/${testType}/tagpath_per_id`).get(),
        ]

        try {
            const documents = await measureTime(
                () => Promise.all(promises),
                `\tOslerData(): downloaded ${testType} app data`)

            this.data[testType][KEYS.TAG_COUNT]     = documents[0].data() ?? {};
            this.data[testType][KEYS.TAG_HIERARCHY] = documents[1].data() ?? {};
            this.data[testType][KEYS.TESTS_PER_TAGPATH] = this.joinCollectionIntoDictionary(documents[2])
            this.data[testType][KEYS.ALL_TESTS_IDS] = this.joinCollectionIntoDictionary(documents[3])
            this.data[testType][KEYS.TAGPATH_PER_ID] = this.joinCollectionIntoDictionary(documents[4])
        } catch (error) {
            console.error("ERROR - Downloading test type data:", error);
            throw error;
        }


    }


    joinCollectionIntoDictionary(collDocs) {
        // Verifica se listDocs é válido e contém documentos
        if (!collDocs || !collDocs.docs || collDocs.docs.length === 0) {            
            return {}; // Retorna um objeto vazio se a lista de documentos for inválida ou vazia
        }

        let dict = {}
        collDocs.docs.forEach(doc => {
            dict = {...dict, ...doc.data()}
        })

        return dict
    }


    joinListDocsIntoDictionary(listDocs) {
        if (!listDocs) {
            return {}
        }
        else {
            let dict = {}
            listDocs.forEach(doc => {
                dict = {...dict, ...doc.data()}
            })
    
            return dict
        }

    }


    async downloadResidenciaSpecificData() {
        let promises = [
            db.doc(`metadata/Residencia/anuladas/anuladas_ids`).get(),
            db.doc(`metadata/Residencia/anuladas/anuladas_tag_count`).get(),
            db.doc(`metadata/Residencia/anuladas/anuladas_per_tagpath`).get(),

            db.doc(`metadata/Residencia/commented/commented_ids`).get(),
            db.doc(`metadata/Residencia/commented/commented_tag_count`).get(),
            db.doc(`metadata/Residencia/commented/commented_per_tagpath`).get(),

            db.doc(`metadata/Residencia/extensivo/extensivo_ids`).get(),
            db.doc(`metadata/Residencia/extensivo/extensivo_tag_count`).get(),
            db.doc(`metadata/Residencia/extensivo/extensivo_per_tagpath`).get(),

            db.doc(`metadata/Residencia/summary/institutions`).get(),
            db.doc(`metadata/Residencia/summary/years`).get(),
            db.doc(`metadata/Residencia/summary/institutions_IDs`).get(),

            db.doc(`metadata/Residencia/summary/exams_metadata`).get(),
        ]


        try {    
            const documents = await measureTime(
                () => Promise.all(promises),
                `\tOslerData(): downloaded Residencia specific data`)

            // Atribuindo dados baixados às chaves apropriadas
            this.data[KEYS.RESIDENCIA][KEYS.ANULADAS] = {
                [KEYS.ALL_IDS]: documents[0].data() ?? {},
                [KEYS.TAG_COUNT]: documents[1].data() ?? {},
                [KEYS.PER_TAGPATH]: documents[2].data() ?? {}
            };
    
            this.data[KEYS.RESIDENCIA][KEYS.COMENTADAS] = {
                [KEYS.ALL_IDS]: documents[3].data() ?? {},
                [KEYS.TAG_COUNT]: documents[4].data() ?? {},
                [KEYS.PER_TAGPATH]: documents[5].data() ?? {}
            };
    
            this.data[KEYS.RESIDENCIA][KEYS.EXTENSIVO] = {
                [KEYS.ALL_IDS]: documents[6].data() ?? {},
                [KEYS.TAG_COUNT]: documents[7].data() ?? {},
                [KEYS.PER_TAGPATH]: documents[8].data() ?? {}
            };
    
            this.data[KEYS.RESIDENCIA][KEYS.INSTITUTIONS] = documents[9].data() ?? {};
            this.data[KEYS.RESIDENCIA][KEYS.YEARS] = documents[10].data() ?? {};
            this.data[KEYS.RESIDENCIA][KEYS.INSTITUTIONS_IDS] = documents[11].data() ?? {};
            this.data[KEYS.RESIDENCIA][KEYS.EXAMS_METADATA] = documents[12].data() ?? {};
    
    
        } catch (error) {
            console.error("Error downloading Residencia specific data:", error);
            throw error;
        }
    }


    async downloadMetadata() {
        try {
            let doc = await measureTime(
                () => db.doc(`metadata/metadata`).get(),
                `\tOslerData(): downloaded general metadata`)

            let data = doc.data()

            this.data[KEYS.RESIDENCIA][KEYS.N_TOTAL_TESTS] = data[KEYS.RESIDENCIA]
            this.data[KEYS.FLASHCARDS][KEYS.N_TOTAL_TESTS] = data[KEYS.FLASHCARDS]
        } catch (error) {
            console.error("Error downloading general metadata", error);
            throw error;
        }
    }


    async downloadUserData() {
        let promises = [
            db.doc(`/users/${this.userID}/personal/residencia_filters`).get(),

            db.doc(`/users/${this.userID}/Residencia/tests/reviews_indexed/ms_per_id_0`).get(),
            db.doc(`/users/${this.userID}/Residencia/tests/reviews_indexed/ms_per_id_1`).get(),
            db.doc(`/users/${this.userID}/Residencia/tests/reviews_indexed/ms_per_id_2`).get(),
            db.doc(`/users/${this.userID}/Residencia/tests/reviews_indexed/ms_per_id_3`).get(),

            db.doc(`/users/${this.userID}/Flashcards/tests/reviews_indexed/ms_per_id_0`).get(),
            db.doc(`/users/${this.userID}/Flashcards/tests/reviews_indexed/ms_per_id_1`).get(),
            db.doc(`/users/${this.userID}/Flashcards/tests/reviews_indexed/ms_per_id_2`).get(),
            db.doc(`/users/${this.userID}/Flashcards/tests/reviews_indexed/ms_per_id_3`).get(),

            db.doc(`/users/${this.userID}/personal/flashcards-buried`).get(),
            db.doc(`/users/${this.userID}/personal/flashcards-liked`).get(),

            db.doc(`/users/${this.userID}/personal/residencia-buried`).get(),
            db.doc(`/users/${this.userID}/personal/residencia-liked`).get(),

            db.doc(`/users/${this.userID}/Residencia/post_its/metadata/tests_with_post_its`).get(),
            db.doc(`/users/${this.userID}/Flashcards/post_its/metadata/tests_with_post_its`).get(),

            db.doc(`/users/${this.userID}/Residencia/mistakes_journal`).get(),

            db.doc(`/users/${this.userID}/notes/notes_metadata`).get(),

            db.doc(`/users/${this.userID}/Residencia/tests/solved_questions/solved_per_id_0`).get(),
            db.doc(`/users/${this.userID}/Residencia/tests/solved_questions/solved_per_id_1`).get(),
            db.doc(`/users/${this.userID}/Residencia/tests/solved_questions/solved_per_id_2`).get(),
            db.doc(`/users/${this.userID}/Residencia/tests/solved_questions/solved_per_id_3`).get(),
        ]

        try {
            const documents = await measureTime(
                () => Promise.all(promises),
                `\tOslerData(): got user reviews and personal data for Flashcards/Residencia`)

            this.data[KEYS.RESIDENCIA][KEYS.USER_FILTERS] = documents[0].data() ?? {};

            this.data[KEYS.RESIDENCIA][KEYS.REVIEWS_INDEXED] = this.joinListDocsIntoDictionary(
                [documents[1], documents[2], documents[3], documents[4]]);

            this.data[KEYS.FLASHCARDS][KEYS.REVIEWS_INDEXED] = this.joinListDocsIntoDictionary(
                [documents[5], documents[6], documents[7], documents[8]]);
                        
            this.data[KEYS.FLASHCARDS][KEYS.BURIED] = documents[9].data() ?? {};
            this.data[KEYS.FLASHCARDS][KEYS.LIKED] = documents[10].data() ?? {};
            
            this.data[KEYS.RESIDENCIA][KEYS.BURIED] = documents[11].data() ?? {};
            this.data[KEYS.RESIDENCIA][KEYS.LIKED] = documents[12].data() ?? {};


            // Claramente, generalizável, todos esses métodos re receber e se não existir
            // dar algo padrão
            if (documents[13].exists) {
                this.data[KEYS.RESIDENCIA][KEYS.TESTS_WITH_STICKIES] = documents[13].data()['data'] ?? [];
            } else {
                this.data[KEYS.RESIDENCIA][KEYS.TESTS_WITH_STICKIES] = [];
            }
            
            if (documents[14].exists) {
                this.data[KEYS.FLASHCARDS][KEYS.TESTS_WITH_STICKIES] = documents[14].data()['data'] ?? [];
            } else {
                this.data[KEYS.FLASHCARDS][KEYS.TESTS_WITH_STICKIES] = [];
            }

            if (documents[15].exists) {
                const data = documents[15].data()

                this.data[KEYS.RESIDENCIA][KEYS.MISTAKES_JOURNAL] = {
                    'needsReview' : data['needsReview'] ?? [],
                    'reviewedTests' : data['reviewedTests'] ?? []                
                }
            }
            else {
                this.data[KEYS.RESIDENCIA][KEYS.MISTAKES_JOURNAL] = {
                    'needsReview' : [],
                    'reviewedTests' : []
                }
            
            }

            this.data[KEYS.NOTES][KEYS.NOTES_METADATA] = documents[16].data() ?? {}
            

            this.data[KEYS.RESIDENCIA][KEYS.SOLVED] = this.joinListDocsIntoDictionary(
                [documents[17], documents[18], documents[19], documents[20]])


            console.log(documents[17].data())
            console.log(this.data[KEYS.RESIDENCIA][KEYS.SOLVED])


        } catch (error) {
            console.error("Error downloading user data for Flashcards/Residencia", error);
            throw error;
        }
    }
}

export default new OslerData()