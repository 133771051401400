
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { db } from '../firebase/firebase-setup'


import './UserFeedbackReview.css'
import { mapIcon, mapMethodology } from '../feedback/FeedbackUtils.js'
import AppContainer from '../app-container/AppContainer.js'

import FeedbackIcon from './../assets/correcao.png'
import CloseIcon from './../assets/x-icon.png'
import QuestionIcon2 from './../assets/question.png'
import styled from 'styled-components'
import ScreenContainer from '../components/ScreenContainer.js'
import ScreenCard from '../components/ScreenCard.js'
import { useNavigate } from 'react-router-dom'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles.js'
import ScreenContainerHeader from '../components/ScreenContainerHeader.js'
import OslerCard from '../components/OslerCard.js'


const FeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 0 2em 3em 2em;
`



const UserFeedbackCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    margin: 2em 1em 0 1em;
    padding: 2em;
    width: 90%;

    
    border: 1px solid gray;
    // box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
    border-radius: 1em;

    @media (max-width: 1200px) {
        width: 80%;
        margin: 2em 0 0 0;
    }

    @media (max-width: 500px) {
        width: 96%;
        margin: 2em 0 0 0;
    }

    ${backgroundWhiteBlack}

`;

const UserFeedbackDetails = styled.p`
    font-size: 0.9em;


    ${props => props.theme.darkMode ? `
        color: rgb(230, 230, 230);
    `:`
        color: black;
    `}
`;

const UserFeedbackDetailsIcon = styled.span`
    margin-right: 0.3em;

    
`;

const UserFeedbackActualFeedback = styled.p`
    margin: 0 0 0 2em;
    font-size: 1.2em;
    font-weight: bold;


    ${props => props.theme.darkMode ? `
        color: rgb(200, 200, 200);
    `:`
        color: black;
    `}

`;

const UserFeedbackActualFeedbackDetail = styled.p`
    margin: 0 0 0 3em;
    font-size: 0.8em;
    color: gray;

    
`;


const UserFeedbackOslerResponse = styled.p`
    margin: 2em 0 0 2em;
    font-size: 1.2em;
    font-weight: bold;
    color: rgb(39, 121, 196);

    color: ${props => {
        switch (props.decision) {
            case 'accepted':
                return 'rgb(39, 121, 196)'; // Verde para aceito
            case 'custom-response':
                return 'rgb(35, 146, 7)'; // Azul para resposta personalizada
            default:
                return '#8E0011'; // Vermelho para outros casos
        }
    }};
`;

const FeedbackHelp = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2em;

    ${props => props.theme.darkMode ? `
        color: rgb(230, 230, 230);
    `:`
        color: black;
    `}
`


export default function UserFeedbackReview(props) {
    const [user, setUser] = useState( useSelector(state => state.user.data) )
    const [feedbacks, setFeedbacks] = useState(false)
    const [showHelp, setShowHelp] = useState(false)

    const testType = 'Flashcards'

    const navigate = useNavigate()

    useEffect(() => {
        async function loadData() {
            const query = await db.collection(`/users/${user.id}/feedback/${testType}/disliked/`).get()

            const feedbacksX = []
            query.forEach(doc => {
                feedbacksX.push(doc.data())
            })

            if (feedbacksX.length >= 1) {
                feedbacksX.sort((a, b) => {
                    if (!a['date_ms']) {
                        return 1
                    }

                    else if (!b['date_ms']) {
                        return -1
                    }

                    else if (a['date_ms'] < b['date_ms']) {
                        console.log(`${a['date']} é antes de ${b['date']}`)
                        return 1; // a vem antes de b
                    }

                    else if (a['date_ms'] > b['date_ms']) {
                        console.log(`${a['date']} é depois de ${b['date']}`)
                        return -1; // a vem depois de b
                    }
                    
                    else {
                        return 0;
                    }
                });
                
                setFeedbacks(feedbacksX)
            }

            console.log(feedbacksX)

        }

        loadData()
    }, [])


    function feedbackCard(feedback) {
        const icon = mapIcon(feedback['type']); // Garanta que mapIcon é uma função definida para mapear o tipo para um ícone
        const theme = feedback['tag_path']?.split('/').pop() ?? '';
        const userFeedback = feedback['user_feedback'] ?? false;
        const oslerAnswer = feedback['osler_answer'] ?? false;
        const sentDate = feedback['date']?.split(',')[0] ?? undefined;
        const solvedDate = feedback['solved_date']?.split(',')[0] ?? false
    
        return (
            <UserFeedbackCard>
                <UserFeedbackDetails>
                    <UserFeedbackDetailsIcon>{icon}</UserFeedbackDetailsIcon>
                    <strong>{theme}</strong> - <span> {feedback['solved'] ? 'Resolvido ✅' : 'Pendente ⚠️' }</span>
                </UserFeedbackDetails>
    
                <div>
                    {userFeedback && <UserFeedbackActualFeedback>{userFeedback}</UserFeedbackActualFeedback>}
    
                    {sentDate && <UserFeedbackActualFeedbackDetail>(Você enviou em {sentDate})</UserFeedbackActualFeedbackDetail>}
    
                    {feedback['solved'] && <>
                        {oslerAnswer && <UserFeedbackOslerResponse decision = {feedback['osler_decision']} >{oslerAnswer}</UserFeedbackOslerResponse>}
                        {solvedDate && <UserFeedbackActualFeedbackDetail>(Nós respondemos em {solvedDate})</UserFeedbackActualFeedbackDetail>}
                    </>}
                </div>
            </UserFeedbackCard>
        );
    }

    return (

            <ScreenContainerHeader
                title = 'Feedbacks'
                icon = {FeedbackIcon} >

                    <FeedbackContainer>

                        <OslerCard>
                            <FeedbackHelp>
                                    <p className='TrackWelcome'>Bem-vindo(a) à <em>Área de Feedback</em>!</p>
                                    <p><strong>Para manter a excelência que você espera do conteúdo, temos um processo editorial rigoroso, e parte dele é o atendimento às críticas e sugestões dos alunos.</strong></p>
                                    <ul>
                                        <li>Typos e erros de conteúdo são corrigidos em poucos dias.</li>
                                        <li>Sugestões de melhoria e metodologia podem demorar dias a semanas, pois precisam ser vistas especificamente pelo autor do deck.</li>
                                        <li>Os feedbacks estão ordenados que foram feitos.</li>
                                        <li>A maioria das sugestões são aceitas, mas nem todas. Não é crowd-sourced. Não leve para o pessoal.</li>
                                        <li>Após aparecer como "Resolvido" aqui, pode demorar 24 a 48 horas para aparecer no aplicativo.</li>
                                        <li>Dúvidas não serão respondidas: infelizmente, não prestamos esse serviço.</li>
                                        <li>Bugs devem ser encaminhados para suporte@osler-ensino.com.</li>
                                        <li>Lembre a simbologia: ✍🏼 é typo, 📚 é erro no fato médico, 🧠 é erro de metodologia, e 🎨 é uma sugestão de melhoria.</li>
                                    </ul>
                                    <p>Essa área está em construção, e adoraríamos ouvir ideias sobre o que você quer ver aqui.</p>
                            </FeedbackHelp>

                            {feedbacks && feedbacks.map(feedback => feedbackCard(feedback))}
                            {!feedbacks && <p>Parece que você ainda não fez nenhum feedback.</p>}

                        </OslerCard>

                    </FeedbackContainer>
            </ScreenContainerHeader>
    );

}