import React from 'react'
import styled from 'styled-components'
import OslerLogo from './../../assets/Logo.png'
import SignatureLogo from './../../assets/signature.png'
import { theme, fadeIn } from './CheckoutStyles'
import { ColumnCSS, RowCSS } from '../../components/BasicComponents'
import OslerCard from '../../components/OslerCard'


const Container = styled.div`
    ${ColumnCSS}
    width: 50%;
    margin: 0 auto;
    background: ${theme.colors.secondary};
    animation: ${fadeIn} 1s ease-out;
    min-height: 100vh;

    padding: 2em;

    @media (max-width: 1200px) {
        // Width a partir do qual vira column, conforme <Checkout>
        min-height: auto;
        padding: 2em 15% 5em 15%;
        width: 100%;
    }

    @media (max-width: 900px) {
        padding: 2em 10% 3em 10%;
    }

    @media (max-width: 770px) {
        padding: 2em 5% 0em 5%;
    }

    @media (max-width: 700px) {
        padding: 2em 0% 0em 0%;
    }

    @media (max-width: 600px) {
        padding: 2em 0% 0em 0%;
    }

`


const Header = styled.header`
    ${RowCSS}
    justify-content: space-around;
    margin-bottom: 1.5em;

    @media (max-width: 600px) {
        margin-bottom: 0em;
        // background-color: cyan;
    }
`

const Logo = styled.img`
    height: 4.5em;
    margin-right: 0.75em;

    @media (max-width: 600px) {
        height: 3.5em;
    }
`

const Signature = styled.img`
    height: 3em;
    margin-left: 0.75em;

    @media (max-width: 600px) {
        height: 2.5em;
    }
`

const BlackFridayBanner = styled.div`
    background: linear-gradient(90deg, #000000, #1a1a1a);
    width: 100%;
    color: #FFD700;
    padding: 0.8em;
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 0.5em;
    margin-bottom: 1em;
`


const Inner = styled.div`
    ${ColumnCSS}
    padding: 0.5em 2em 3em 2em;
    align-items: center;

    @media (max-width: 600px) {
        padding: 0;
        // background-color: pink;
    }
`

const PriceRow = styled.div`
    ${RowCSS}
    // height: 5em;
    // width: 100%;


    // background-color: yellow;

    @media (max-width: 600px) {
        flex-direction: column;
        height: auto;
        align-items: center;
        gap: 0em;
    }
`

const CurrentPrice = styled.div`
    ${ColumnCSS}
    justify-content: center;

    p {
        font-size: 2.5em;
        font-weight: bold;
        color: ${theme.colors.primary};
        margin: 0;
        padding: 0;
    }

    @media (max-width: 600px) {
        p {
            font-size: 2em;
        }
    }
`


const Discount = styled.div`
    ${ColumnCSS}
    margin-left: 0.5em;
    align-items: flex-start;
    height: 100%;
    
    @media (max-width: 600px) {
        margin-left: 0;
        align-items: center;
    }
`

const OriginalPrice = styled.div`
    ${ColumnCSS}
    justify-content: center;
    height: 50%;

    p {
        color: ${theme.colors.text.secondary};
        text-decoration: line-through;
        font-size: 1.1em;
        margin: 0;
    }

    // background-color: red;

    @media (max-width: 600px) {
        margin: 0em 0.5em 0 0.5em;
        padding: 0.8em;
    }
`


const SavingsTag = styled.div`
    ${ColumnCSS}
    justify-content: center;
    height: 50%;

    p {
        font-size: 1.1em;
        color: green;
        font-weight: bold;
        margin: 0;
    }

    // background-color: pink;
`;


const Period = styled.p`
    // width: 100%;
    // margin-top: 1em;
    
    font-weight: bold;
    color: gray;

    @media (max-width: 600px) {
        text-align: center;

    }
`



const FeaturesList = styled.div`

    margin: 1.5em 1em 0 0;
    border-radius: 1em;
    padding: 1em;

    border: 1px solid darkgray;

    display: flex;
    flex-direction: column;
    gap: 0.8em;
`

const FeatureItem = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 0.8em;
    padding: 0.4em 0;

    .icon {
        width: 20px;
        height: 20px;
        min-width: 20px;
        background: ${theme.colors.primary}15;
        color: ${theme.colors.primary};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8em;
        font-weight: bold;
        margin-top: 0.2em;
    }

    span {
        font-size: 0.9em;
        color: ${theme.colors.text.primary};
        font-weight: 500;
        line-height: 1.4;
    }
`


export default function CheckoutInfo() {
    const benefits = [
        "+47.000 flashcards baseados no UpToDate e em livros clássicos",
        "+43.000 questões, de todo o Brasil, todas comentadas",
        "qBank completo: provas na íntegra, lista de questões personalizadas, exportar em pdf, etc",
        "Simulados com questões próprias (2025)",
        "Estatísticas detalhadas e planos de revisão e recuperação",
        "Cronogramas para R1: Extensivo, Semi-Extensivo, Intensivão",
        "Material de multimídia completo: semiologia, ECG, exames de imagem, e exames complementares",
        "Crie resumos associados aos cards e questões",
        "Sistema de feedback e atualização contínua do conteúdo",
        "Desconto expressivo na renovação"
    ];

    const savings = 3600 - 2697;
    const savingsPercentage = Math.round((savings / 3600) * 100);

    return (
        <Container>
            <Header>
                <Logo      src={OslerLogo}  />
                <Signature src={SignatureLogo} />
            </Header>

            <OslerCard style = {{padding: '2em 1em 1em 1em'}}>
                {/* <BlackFridayBanner>
                    Black Friday 2024 • Oferta por tempo limitado
                </BlackFridayBanner>
 */}

                <Inner>
                    {/* <PriceRow>
                        <CurrentPrice>
                            <p>R$ 3.600,00</p>
                        </CurrentPrice>
                        <Discount>
                            <OriginalPrice>
                                <p>R$ 3.600,00</p>
                            </OriginalPrice>
                            <SavingsTag>
                                <p>R$903 de desconto (25% off)</p>
                            </SavingsTag>
                        </Discount>
                    </PriceRow> */}

                    <PriceRow>
                        <CurrentPrice>
                            <p>R$ 3.600,00</p>
                        </CurrentPrice>
                    </PriceRow>

                    <Period>Acesso até 31 de Janeiro de 2026.</Period>

                    <FeaturesList>
                        {benefits.map((benefit, index) => (
                            <FeatureItem key={index}>
                                <div className="icon">✓</div>
                                <span>{benefit}</span>
                            </FeatureItem>
                        ))}
                    </FeaturesList>
                </Inner>


            </OslerCard>
        </Container>
    );
}