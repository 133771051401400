import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ReviewsIcon from './../assets/dumbbell.png'
import FlashcardsIcon from './../assets/flashcard.png'
import ResidenciaIcon from './../assets/residencia.png'
import Balloons from './../assets/tree/balloons.png'
import { ColumnCSS } from '../components/BasicComponents'
import { darkModeGreen } from '../tests/FlashcardsStyles'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    @media (max-width: 900px) {
        margin-top: 1.5em;
        width: 100%;
        padding: 0;
        justify-content: flex-end;
    }

`



const Card = styled.div`
    ${ColumnCSS}
    align-self: flex-end;

    // Quando não tem revisões, o card é menor e verde.

    padding: 1em;


    ${props => `
        background-color: ${
          props.theme.darkMode
            ? props.hasReviews
              ? 'rgba(61, 61, 61, 1)'
              : darkModeGreen
            : props.hasReviews
              ? 'white'
              : 'rgba(87, 191, 65, 0.9)'
        };
      `}


    width: 20em;
    @media (max-width: 1200px) {
        width: 18em;
    }

    @media (max-width: 900px) {
        width: 16em;
    }

    @media (max-width: 500px) {
        width: 14em;
        padding: 0.8em;
    }

    // Sombras somente no modo claro
    ${props => !props.theme.darkMode && `
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.32) 0px 1px 4px;
        }
    `}

    border-radius: 1em;
    margin-bottom: 1em;
`



const BttnsContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    img {
        height: 2em;
    }

    @media (max-width: 900px) {
        img {
            height: 1.8em;
        }
    }

    @media (max-width: 500px) {
        img {
            height: 1.4em;
        }
    }
`


const BttnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35%;

    padding: 0.5em;
    border-radius: 0.5em;

    background-color: ${props => props.checked ? `none` : `rgba(235, 235, 235, 0.6)`};

    &:hover {
        ${props => !props.checked && `
            background-color: rgba(235, 235, 235, 0.9);
            cursor: pointer;
        `}
    }

    p {
        margin: 0;
        font-size: 0.9em;
        font-weight: bold;
        color: #8E0011;
    }

    img {
        margin-right: 0.5em;
        height: 1.8em;
    }

    @media (max-width: 900px) {
        padding: 0.6em;
        
        img {
            height: 1.6em;
            margin-right: 0.4em;
        }

        p {
            font-size: 0.9em;
        }
    }

    @media (max-width: 500px) {
        padding: 0.5em;
        
        img {
            height: 1.4em;
            margin-right: 0.3em;
        }

        p {
            font-size: 0.75em;
        }
    }
`

const BalloonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    img {
        margin-right: 0.5em;
        height: 1.8em;
    }
    
    p {
        margin: 0;
        font-size: 0.9em;
        font-weight: bold;
        text-align: center;
    }

    @media (max-width: 900px) {
        img {
            height: 1.6em;
        }
        
        p {
            font-size: 0.9em;
        }
    }

    @media (max-width: 500px) {
        img {
            height: 1.4em;
        }
        
        p {
            font-size: 0.85em;
        }
    }
`




function Bttn({icon, reviews, onClick}) {
    return (
        <BttnContainer checked = { reviews.length === 0 } onClick = {onClick} >
            <img src = {icon} />
            { reviews.length > 0 &&
                <p>{reviews.length}</p>
            }
            { reviews.length === 0 &&
                <p>✅</p>
            }
        </BttnContainer>
    )
}


export default function ExtensivoReviews({ modules, startTestModeSession }) {
    /*
        Recebemos a lista de módulos, e extraímos todas as revisões pendentes.
    */
   const [cardsReviews, setCardsReviews] = useState([])
   const [residReviews, setResidReviews] = useState([])
   const [hasReviews, setHasReviews] = useState(false)


    useEffect(() => {
        if (modules) {
            const data = modules.reduce((acc, module) => {
                module.steps.forEach(step => {
                    step.activities.forEach(activity => {
                        if (activity.reviewFlashcards && activity.reviewFlashcards.length > 0) {
                            acc.reviewFlashcards = acc.reviewFlashcards.concat(activity.reviewFlashcards)
                        }
                        if (activity.reviewResidencia && activity.reviewResidencia.length > 0) {
                            acc.reviewResidencia = acc.reviewResidencia.concat(activity.reviewResidencia)
                        }
                    })
                })
                return acc
            }, { reviewFlashcards: [], reviewResidencia: [] })

            setCardsReviews(data.reviewFlashcards)
            setResidReviews(data.reviewResidencia)
            setHasReviews(data.reviewFlashcards.length > 0 || data.reviewResidencia.length > 0)

    }
    }, [JSON.stringify(modules)])



    function start(testType, testsIDs) {
        startTestModeSession(testType, testsIDs, true, 'Revisões do Cronograma')
    }


    return (
        <Container>
            <Card hasReviews = {hasReviews} >
                {hasReviews ? (
                        <BttnsContainer>
                            <img src={ReviewsIcon} alt="Reviews Icon" />
                        
                            <Bttn 
                                icon={FlashcardsIcon} 
                                reviews={cardsReviews} 
                                onClick = {() => start('Flashcards', cardsReviews)}/>

                            <Bttn 
                                icon={ResidenciaIcon}
                                reviews={residReviews}
                                onClick = {() => start('Residencia', residReviews)}/>
                        </BttnsContainer>
                    ) : (
                        <BalloonContainer>
                            <img src={ReviewsIcon} alt="Reviews Icon" />
                            <img src={Balloons} alt="Balloons Icon" />
                            <p>Nenhuma revisão pendente!</p>
                        </BalloonContainer>
                    )}
            </Card>
        </Container>
    )
}


