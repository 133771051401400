import React, { useState } from 'react';
import styled from 'styled-components';


export const TestCardContainer = styled.div`
    display: flex;
    flex-direction: column;



    justify-content: space-between;
    align-items: center;
    border-radius: 0.25em;
    width: 100%;
    padding: 4em;
   
    ul, ol {
        text-align: left;
        margin-top: 0;
        margin-left: 3em;
        margin-bottom: 1.5em;

        @media (max-width: 500px) {
            text-align: left;
        margin-top: 0;
        margin-left: 0em;
        margin-bottom: 1.5em;
        }
    }

    li {
        margin-bottom: 0.2em;
    }

    ${props => props.theme.darkMode ? `
        background-color: rgba(81, 81, 81, 1);


        box-shadow: none;

        @media (max-width: 500px) {
            border: 1px solid gray;
        }

    ` : `
        background-color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    `}


    // Fundamental no desktop, para afasar da navbar e dos helper buttons
    // Removemos no mobile.
    // margin-top: 3em;
    margin-bottom: 1em;



    @media (max-width: 500px) {
        padding: 0em 1em 1em 1em;
        box-shadow: none;
        background: none;
        border: none;
        // margin: 2em 0 0 0;

        margin-bottom: 0em;

    }
`



export default function TestContainer({style, children}) {
    return (
        <TestCardContainer style = {style} >
            {children}
        </TestCardContainer>
    );
}


