import { db } from './../firebase/firebase-setup'
import { dateIntoString, getStateName, getTodayMidnight, tagsFromArrayToFirebase } from './../utils/Utils'
import firebase from 'firebase/compat/app';
import MistakesJournalController from './MistakesJournalController';
import { createEmptyCard, default_w, FSRS, Rating, State } from 'ts-fsrs';
import OslerFSRS from './OslerFSRS';
/*
    Essa classe possui métodos que obtém e/ou atualizam as
    estatísticas específicas DE UM ÚNICO TESTE.
*/ 



class TestStatistics {
    constructor(userID, testType) {
        this.userID = userID
        this.testType = testType
        this.fsrs = new FSRS({
            request_retention: 0.9,
            maximum_interval: 365/2
        })
    }


    async getTestStatistics(testID) {
        // Todo teste possui um documento individual sobre suas estatísticas.
        // Primeiro, e mais importante, o necessário para a repetição espaçada:
        //      - data para qual estava agendada sua revisão (due date), legível e em nanosegundos
        //      - seu ease factor
        //      - tipo (new test, lapsed, learning)
        //      - data em que foi visto pela última vez, legível e em nanosegundos
        //
        //
        // Segundo, importante para fins de debug, um registro detalhado de todas 
        // as respostas que já foram dadas a ele, em um array de objetos:
        //      {
        //          reviewDate:
        //              reviewDateReadable:
        //          levelOfSuccess: 
        //          resposta dada (se MCQ): 
        //      }
        //
        // Se esse documento não existe, derivamos que o teste nunca havia sido respondido.
        console.log(` * Baixando estatísticas de ${testID}`)
        try {
            const coll = db.collection("users").doc(this.userID).collection(this.testType).doc("tests").collection("reviewed tests")
            const doc = await coll.doc(testID).get()
    
            if (doc.exists) {
                const data = doc.data()
                return data
            }
            else {
                console.log(`\t Teste nunca respondido: ${testID}`)

                if (this.testType === 'Flashcards') {
                    return OslerFSRS.createNewFlashcard()
                }
                else {
                    return {
                        'log' : []
                    }
                }

            }
        }
        catch (error) {
            console.log('\t ERROR - getTestStatistics()')
            console.log(`\t ERROR - ${testID} - ${this.userID} - ${this.testType}`)
            console.log(error)            
        }
    }


    getNextReviewForRating(testStatistics, levelOfSuccess) {
        function getRatingFromLevelOfSuccess(levelOfSuccess) {
            switch (levelOfSuccess) {
                case 0: return 'Again'
                case 1: return 'Hard'
                case 2: return 'Good'
                case 3: return 'Easy'
            }
        }

        const rating  = getRatingFromLevelOfSuccess(levelOfSuccess)

        console.log(testStatistics)
        console.log(rating)

        const flashcard  = OslerFSRS.createFlashcardFromFirebase(testStatistics)

        console.log(flashcard)
        
        const nextReview = OslerFSRS.getNextReviewForRating(flashcard, rating)        


        console.log(nextReview)

        return nextReview
    }


    readableTimeUntilNextReview(testStatistics) {
        const flashcard = OslerFSRS.createFlashcardFromFirebase(testStatistics)
        const now = new Date()

        return OslerFSRS.getReadableTimeUntilNextReview(flashcard, now)
    }



    updateFlashcardStatistics(test, levelOfSuccess, timeSpent) {
        const testStats = test.statistics
        const result = this.getNextReviewForRating(testStats, levelOfSuccess)

        const newCard = result.card

        result.log['timeSpent'] = timeSpent

        console.log(testStats.log)
        console.log(result.log)

        // const data = {
        //     'card' : newCard,
        //     'log' : newLog
        // }

        const newType   = getStateName(newCard.state).toLowerCase()
        const dueDateMs = new Date(newCard.due).getTime()

        console.log(result)

        return [newType, dueDateMs, result]
    }



    updateResidenciaStatistics(test, metacognition, timeSpent, mcqAnswer, gaveRightAnswer) {
        // Desde Jun 2024, as estatísticas das questões de residência são diferentes.
        const testStats = test.statistics
        const today = getTodayMidnight()


        // Criamos um log específico a essa revisão, que é adicionado ao fim do array.
        var log = {
            'date' : today.getTime(),
            'dateReadable' : dateIntoString(today),
            'timeSpent' : timeSpent,
            'gaveRightAnswer' : gaveRightAnswer,
            'mcqAnswer' : parseInt(mcqAnswer),
            'metacognition' : metacognition,
        }
        
        const logHistory = testStats.log
        logHistory.push(log)


        let data = {
            'lastReviewReadable' : dateIntoString(today),
            'lastReview' : today.getTime(),
            'log' : logHistory,
        }

        if (['rightGuess', 'wrongConcept', 'wrongDistraction'].includes(metacognition)) {
            // Revisão é em +7 dias
            const nextReviewDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)

            data['nextReview'] = nextReviewDate.getTime()
            data['nextReviewReadable'] = dateIntoString(nextReviewDate)

            MistakesJournalController.addAsReview(test.testID)
        } 
        else {
            data['nextReview'] = firebase.firestore.FieldValue.delete()
            data['nextReviewReadable'] = firebase.firestore.FieldValue.delete()

            MistakesJournalController.changeToReviewed(test.testID)
        }


        return [metacognition, data['nextReview'], data]
    }
}

export default TestStatistics
   


