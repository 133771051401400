import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase/firebase-setup';
import { htmlToReact } from '../utils/HtmlToReact';
import CloseIcon from './../assets/x-icon.png';
import { useSelector } from 'react-redux';
import OslerButton from '../components/OslerButton';
import { Column, ColumnCSS, RowCSS } from '../components/BasicComponents';


import NoticeEmoji from './../assets/message.png'

import { 
    BookOpen,  
    Award, 
    Target, 
} from 'lucide-react'
import TitleCard from '../custom/TitleCard';
import BibliothecaText from '../bibliotheca/BibliothecaText';
import { darkBackgroundSecondaryColor, darkBackgroundTertiaryColor } from '../tests/FlashcardsStyles';




const Bttns = styled.div`
    ${RowCSS}

    @media (max-width: 900px) {
        width: 100%;
        margin-top: 2em;
        justify-content: flex-end;
    }
`



const Metadata = styled.p`
    font-size: 0.9em;
    color: gray;
    font-weight: bold;

    align-self: flex-end;
    margin-top: 1em;
    margin-bottom: 2em;
`


export default function NoticeCard(props) {

    const user = useSelector(state => state.user.data)

    const [showNotice, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [uuid, setUuid] = useState("")
    const [date, setDate] = useState("")

    const [isDesktop, setDesktop] = useState(window.innerWidth > 900);


    useEffect(() => {
        async function load() {
            const noticesDoc = await db.collection('notice').doc('lastNotices').get()
            
            if (noticesDoc.exists) {   
                const notices = noticesDoc.data().lastNotices
                if (!notices || notices.length === 0) {
                    console.log("NoticeCard: não temos avisos novos")
                    return
                }
    
                const lastNotice = notices[notices.length - 1]
                
                const noticeDate = lastNotice.date?.toDate()
                if (noticeDate) {
                    const hoursDiff = (new Date() - noticeDate) / (1000 * 60 * 60)
                    if (hoursDiff > 48) {
                        console.log("NoticeCard: aviso é antigo")
                        return
                    }
                }
    
                const alreadyReadDoc = await db.doc(`users/${user.id}/personal/readLastNotice`).get()    
                if (alreadyReadDoc.exists) {
                    const alreadyRead = alreadyReadDoc.data()

                    console.log(alreadyRead)

                    if (alreadyRead['id'] === lastNotice['id']) {
                        console.log("NoticeCard: já leu o aviso");
                        setShow(false)
                        return
                    }
                }


                const formattedDate = noticeDate ? formatDate(noticeDate) : ''

                setTitle(lastNotice['title'])
                setDate(formattedDate)
                setMessage(htmlToReact(lastNotice.message))
                setShow(true)
                setUuid(lastNotice['id'])

            
            } else {
                console.log("NoticeCard: não temos avisos novos")
            }
        }

        console.log("NoticeCard: loading...")
        load()
    }, [])

    function formatDate(date) {
        return date.toLocaleDateString('pt-BR', { 
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    function bttns() {
        return (
            <Bttns>
                <OslerButton
                    size = 'small'
                    color = 'red'
                    onClick = {async () => {
                        setShow(false)
                        await db.doc(`users/${user.id}/personal/readLastNotice`).set({id: uuid})
                    }}
                    text = 'Não mostrar mais'
                    style = {{marginRight: isDesktop ? '2em' : '1em'}} />

                <OslerButton
                    size = 'small'
                    color = 'gray'
                    onClick = {() => setShow(false)}
                    text = 'Fechar'  />
            </Bttns>
        )
    }

    const darkMode = useSelector(state => state.theme.darkModeOn)

    const background = darkMode ? darkBackgroundSecondaryColor : 'linear-gradient(135deg, #fffaf0 0%, #fff5f5 100%)'

    return (
        <>
        { showNotice && 
            <TitleCard
                style = {{background: background, maxWidth: '900px'}}
                title = {title}
                icon = {NoticeEmoji}
                rightComponent={ isDesktop ? bttns() : null}
                >

                    <Metadata>
                        {date}
                    </Metadata>

                    <BibliothecaText>
                        {message}
                    </BibliothecaText>

                    { !isDesktop && bttns() }

            </TitleCard>
        }
        </>
    )
}
