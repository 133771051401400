

import styled from 'styled-components'
import { darkModeBlue, textWhiteBlack } from '../tests/FlashcardsStyles';


const StyledBibliothecaText = styled.div`
    h3 {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 2rem 0 0.2rem 0;
        line-height: 1.3;

        color: ${props => props.theme.darkMode ? darkModeBlue : `#264bab`};
     
        &:first-child {
            margin-top: -0.5em;
        }
    }


    // Para casos atípicos onde o conteúdo do <li> não
    // está dentro de um <p>
    p, li {
        ${textWhiteBlack}
        font-size: 1.125rem;
        text-align: justify;
        line-height: 1.6;
    }


    p {
        ${textWhiteBlack}
        margin: 0 0 1.5rem 0; 
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export default function BibliothecaText({ children }) {
    return <StyledBibliothecaText>{children}</StyledBibliothecaText>
}

